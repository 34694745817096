import { FC, PropsWithChildren } from 'react'

export const Form: FC<
    React.DetailedHTMLProps<
        React.FormHTMLAttributes<HTMLFormElement>,
        HTMLFormElement
    >
> = ({ onSubmit, ...props }) => {
    const onSubmitMiddle = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (onSubmit) return onSubmit(e)
    }

    return <form {...props} onSubmit={onSubmitMiddle} className="form" />
}

export const FormElement: FC<PropsWithChildren> = ({ children }) => {
    return <div className="form__element">{children}</div>
}

export const FormCaptcha: FC<PropsWithChildren> = ({ children }) => {
    return <div className="form__captcha">{children}</div>
}

export const FormHalf: FC<PropsWithChildren> = ({ children }) => {
    return <div className="form__half">{children}</div>
}

export const FormLabel: FC<PropsWithChildren> = ({ children }) => {
    return <div className="form__label">{children}</div>
}

export const FormButtons: FC<PropsWithChildren> = ({ children }) => {
    return <div className="form__btns">{children}</div>
}

export const FormLinks: FC<PropsWithChildren> = ({ children }) => {
    return <div className="form__links">{children}</div>
}

export const FormDoc: FC<PropsWithChildren> = ({ children }) => {
    return <div className="form__doc">{children}</div>
}

export const FormLink: FC<PropsWithChildren<{ onClick?: () => void }>> = ({
    children,
    onClick,
}) => {
    return (
        <div onClick={onClick} className="form__link">
            {children}
        </div>
    )
}
