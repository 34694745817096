import Axios from 'axios'
import { LS } from '../localStorage'

const a = Axios.create({
    baseURL: '/api',
})

a.interceptors.request.use((req) => {
    const token = LS.token.get()

    if (token) {
        req.headers.Authorization = `Bearer ${token}`
    }

    return req
})

export const axios = a
