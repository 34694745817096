import classNames from 'classnames'
import { FC } from 'react'

export const Colors: FC<{
    colors: [string, string][]
    value?: string
    onChange?: (color: string) => void
}> = ({ colors, value, onChange }) => {
    function change(label: string) {
        if (onChange) onChange(label)
    }
    return (
        <div className="colors-picker">
            {colors.map(([label, color]) => (
                <div
                    key={color}
                    className={classNames('color-picker', {
                        'color-picker_active': label === value,
                    })}
                    style={{ backgroundColor: color }}
                    onClick={() => change(label)}
                ></div>
            ))}
        </div>
    )
}
