import { useEffect, useState } from 'react'

const localState = new Map()

export function useGlobalState<T>(index: string, def: T) {
    const [state, setState] = useState<T>(localState.get(index) || def)

    useEffect(() => {
        localState.set(index, state)
    }, [state, index])

    return { state, setState }
}
