import { FC, PropsWithChildren } from 'react'
import styles from './Input.module.scss'
import classNames from 'classnames'

export interface InputContainerProps {
    msg?: string
    error?: boolean
}

export const InputContainer: FC<PropsWithChildren<InputContainerProps>> = ({
    children,
    msg,
    error,
}) => {
    return (
        <div className={styles.container}>
            {children}
            {!!msg && (
                <div
                    className={classNames(styles.msg, {
                        [styles.error]: error,
                    })}
                >
                    {msg}
                </div>
            )}
        </div>
    )
}
