import { useParams } from 'react-router'
import { animeAPI } from '../common/api'
import { AnimeDetail } from '../components/Anime/Detail'
import { AnimeMini } from '../components/Anime/ListMini'
import { Block, BlockContent, BlockTitle } from '../components/Block'
import { useTitle } from '../hooks/useTitle'
import { useQuery } from '@tanstack/react-query'
import ms from 'ms'

export const AnimeDetailPage = () => {
    const { id = '0' } = useParams<{ id: string }>()

    // const showAnimeListCount = useTypedSelector((s) => s.showAnimeListCount)

    const { data: anime } = useQuery([`anime`, id], () => animeAPI.item(id), {
        staleTime: ms('10m'),
    })
    // const { data: usersLists } = useFetch(
    //     { name: `animeUserList/${id}`, continue: showAnimeListCount },
    //     useCallback(async () => animeAPI.list({ anime_id: +id }), [id])
    // )
    const { data: seasons } = useQuery(
        [`anime/seasons/${id}`],
        () => animeAPI.seasons(id),
        { staleTime: ms('10m') }
    )

    useTitle({
        title: anime?.data?.title,
        description: anime?.data.description || undefined,
    })

    return (
        <>
            {!!anime && (
                <AnimeDetail
                    anime={anime.data}
                    // lists={usersLists || []}
                ></AnimeDetail>
            )}
            {(seasons?.data?.length || 0) > 0 && (
                <>
                    <Block>
                        <BlockTitle>Связанное аниме</BlockTitle>
                        <BlockContent className="anime-mini__container">
                            {seasons?.data?.map((anime) => (
                                <AnimeMini
                                    key={anime.id}
                                    anime={anime}
                                    active={anime.id.toString() === id}
                                ></AnimeMini>
                            ))}
                        </BlockContent>
                    </Block>
                </>
            )}
        </>
    )
}
