import * as Yup from 'yup'

export const AuthValidation = {
    name: Yup.string()
        .min(3, 'Минимальная длина ника 3 символа')
        .max(15, 'Максимальная длина ника 15 символов')
        .required('Данное поле является обязательным'),
    description: Yup.string().max(256, 'Максимально символов в описании 256'),
    city: Yup.string().max(30, 'Максимально символов города 30'),
    email: Yup.string()
        .email('Введите корректный E-mail')
        .required('Данное поле является обязательным'),
    password: Yup.string()
        .min(6, 'Минимальное кол-во символов 6')
        .required('Данное поле является обязательным'),
    key: Yup.string().required('Данное поле является обязательным'),
}
