import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { images } from '../assets/assets'
import { IUser } from '../types/user'

export function useUser(user?: IUser) {
    const [t] = useTranslation()
    const role = useMemo(
        () => t(`user.role.${user?.role || 'user'}`),
        [user, t]
    )
    const gender = useMemo(
        () => (!!user?.gender ? t(`user.gender.${user.gender}`) : null),
        [user, t]
    )
    const avatar = useMemo(() => user?.img || images.user, [user])
    return { role, gender, avatar }
}
