import { useQuery } from '@tanstack/react-query'
import { animeAPI } from '../common/api'
import { Anime } from '../components/Anime/List'
import { Block } from '../components/Block'
import { Pagination } from '../components/UI/Pagination'
import { usePage } from '../hooks/usePage'

export const RecsDeletedPage = () => {
    const [page, setPage] = usePage()

    const { data, refetch } = useQuery(['/recs/deleted', page + 1], () =>
        animeAPI.recs({ deleted: 1, page: page + 1 })
    )

    return (
        <Block>
            <div className="animes">
                {data &&
                    data.data.data.map(
                        (rec) =>
                            !!rec.anime && (
                                <Anime
                                    anime={rec.anime}
                                    onDeleted={() => refetch()}
                                    rec={rec}
                                ></Anime>
                            )
                    )}
            </div>
            {data && (
                <Pagination
                    data={data?.data}
                    state={[page, setPage]}
                ></Pagination>
            )}
        </Block>
    )
}
