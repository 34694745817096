import { useDispatch } from 'react-redux'
import { AppDispatch } from '../store/types'
import { useCallback } from 'react'
import { LS } from '../common/localStorage'
import { animeAPI, authAPI, metaAPI } from '../common/api'
import { authSlice } from '../store/slices/auth'
import { metaSlice } from '../store/slices/meta'

export const useAppDispatch: () => AppDispatch = useDispatch

export const useAppDipatchMods = () => {
    const dispatch = useAppDispatch()

    const fetchAuth = useCallback(async () => {
        if (!LS.token.get()) return
        const user = await authAPI.fetch().then((res) => res.data)
        dispatch(authSlice.actions.setUser(user))

        await Promise.all([
            animeAPI.list({ user_id: user.id }).then((res) => {
                dispatch(authSlice.actions.setList(res.data))
            }),
            authAPI.notifications.count().then((res) => {
                dispatch(authSlice.actions.setNotifications(res.data))
            }),
        ])
    }, [dispatch])

    const fetchMetaData = useCallback(async () => {
        const { data } = await metaAPI.anime()
        dispatch(metaSlice.actions.setGenres(data.genres))
        dispatch(metaSlice.actions.setTypes(data.types))
    }, [dispatch])

    return { fetchAuth, fetchMetaData }
}
