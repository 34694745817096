import { IAnime } from '../../types/anime'
import { IGenre } from '../../types/genre'
import { IList, ListStatus } from '../../types/list'
import { INotify } from '../../types/notify'
import { IRecs } from '../../types/recs'
import { IUser } from '../../types/user'
import { LS } from '../localStorage'
import { createFormData } from '../utils'
import { axios } from './axios'
import { AS, baseParams, IPagination, ResponseToken } from './types'

export const authAPI = {
    fetch() {
        return axios.get<IUser>('/auth')
    },
    login(body: { email: string; password: string }) {
        return axios
            .post<any, AS<ResponseToken>>('/auth/login', body)
            .then((res) => {
                LS.token.set(res.data.access_token)
                return res
            })
    },
    register(body: { email: string; password: string; captcha_token: string }) {
        return axios
            .post<any, AS<ResponseToken>>('/auth/register', body)
            .then((res) => {
                LS.token.set(res.data.access_token)
                return res
            })
    },
    resetPass: {
        first(data: { email: string; captcha_token: string }) {
            return axios.post('/auth/reset-password', data)
        },
        second(data: { email: string; key: number }) {
            return axios
                .post<any, AS<ResponseToken>>(
                    '/auth/confirm-reset-password',
                    data
                )
                .then((res) => {
                    LS.token.set(res.data.access_token)
                    return res
                })
        },
    },
    logout() {
        return axios.post('/auth/logout').then(() => {
            LS.token.remove()
        })
    },
    update(body: {
        name?: string
        description?: string
        city?: string
        gender?: string | null
        password?: string
    }) {
        return axios.put<any, AS<IUser>>('/auth', body)
    },
    updateAvatar(file: File) {
        return axios.post<any, AS<IUser>>(
            '/auth/avatar',
            createFormData({ file })
        )
    },
    notifications: {
        list(params?: baseParams) {
            return axios.get<any, AS<IPagination<INotify>>>('/notification', {
                params,
            })
        },
        count() {
            return axios.get<any, AS<number>>('/notification/count')
        },
        read(id: number) {
            return axios.put<any>(`/notification/${id}`)
        },
        delete(id: number) {
            return axios.delete<any>(`/notification/${id}`)
        },
    },
}

export const animeAPI = {
    fetch(
        params?: baseParams & {
            year_from?: number
            year_to?: number
            ep_from?: number
            ep_to?: number
            type?: string[]
            status?: 'released' | 'ongoing' | 'anons'
            title?: string
            order?: string
            order_by?: string
            limit?: number
            min_votes?: number
            genres?: string[]
        }
    ) {
        return axios.get<any, AS<IPagination<IAnime>>>('/anime', { params })
    },
    item(id: string) {
        return axios.get<any, AS<IAnime>>(`/anime/${id}`)
    },
    seasons(anime_id: string) {
        return axios.get<any, AS<IAnime[]>>(`/anime/${anime_id}/seasons`)
    },
    list(params?: { anime_id?: number; user_id?: number }) {
        return axios.get<any, AS<IList[]>>(`/anime/lists`, { params })
    },
    updateList(data: { anime_id: number; status: ListStatus }) {
        return axios.put<any, AS<IList>>(`/anime/list`, data)
    },
    recs(params?: baseParams & { deleted?: number }) {
        return axios.get<any, AS<IPagination<IRecs>>>(`/anime/recs`, { params })
    },
    deleteRecs(id: number) {
        return axios.delete(`/anime/recs/${id}`)
    },
}

export const metaAPI = {
    anime() {
        return axios.get<
            any,
            AS<{
                genres: IGenre[]
                statuses: string[]
                types: string[]
                ratings: string[]
            }>
        >(`/meta/anime`)
    },
}

export const userAPI = {
    list() {
        return axios.get<any, AS<IPagination<IUser>>>(`/user`)
    },
    get(id: number) {
        return axios.get<any, AS<IUser>>(`/user/${id}`)
    },
}

export const adminAPI = {
    parseFromShiki(data: { shiki_id: string }) {
        return axios.post<any, AS<IAnime>>('/admin/anime/shiki-parse', data)
    },
    parseFromKodik(animeId: string) {
        return axios.post<any, AS<IAnime>>(
            `/admin/anime/kodik-parse/${animeId}`
        )
    },
}
