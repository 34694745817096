import { FC, PropsWithChildren, useMemo } from 'react'
import { IPagination } from '../../common/api/types'
import { usePage } from '../../hooks/usePage'
import styles from './Pagination.module.scss'
import { BaseButton, BaseButtonProps } from './Button/Base'
import { _ } from '../../common/lodash'
import classNames from 'classnames'

const PaginationButton: FC<PropsWithChildren<BaseButtonProps>> = ({
    theme,
    className,
    contentClassName,
    ...props
}) => {
    return (
        <BaseButton
            theme={theme || 'theme'}
            className={classNames(styles.button, className)}
            contentClassName={classNames(
                styles.buttonContent,
                contentClassName
            )}
            {...props}
        />
    )
}

export const Pagination: FC<{
    data: IPagination<any>
    state: ReturnType<typeof usePage>
}> = ({ data, state }) => {
    const [page, setPage] = state

    function set(page: number) {
        if (page < 0) return
        if (page >= data.last_page) return
        setPage(page)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const pages = useMemo(() => {
        const arrayPages = Array(data.last_page)
            .fill('')
            .map((_, index) => index)
        const result: number[] = []
        const first = arrayPages[0] as number | undefined
        const prev = arrayPages[page - 1] as number | undefined
        const current = arrayPages[page] as number | undefined
        const next = arrayPages[page + 1] as number | undefined
        const last = arrayPages[arrayPages.length - 1] as number | undefined

        if (!_.isUndefined(first)) {
            if (first !== current && first !== prev) result.push(first)
        }

        if (!_.isUndefined(prev)) result.push(prev)

        if (!_.isUndefined(current)) result.push(current)

        if (!_.isUndefined(next)) result.push(next)

        if (!_.isUndefined(last)) {
            if (last !== next && last !== current) result.push(last)
        }

        return result
    }, [page, data])

    if (data.last_page <= 1) return null

    return (
        <div className={styles.container}>
            <PaginationButton onClick={() => set(page - 1)}>
                <i className="fa-solid fa-angle-left"></i>
            </PaginationButton>
            {pages.map((index) => (
                <PaginationButton
                    key={index}
                    active={index === page}
                    onClick={() => set(index)}
                >
                    {index + 1}
                </PaginationButton>
            ))}
            <PaginationButton onClick={() => set(page + 1)}>
                <i className="fa-solid fa-angle-right"></i>
            </PaginationButton>
        </div>
    )
}
