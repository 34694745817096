import { FC, PropsWithChildren, ReactNode } from 'react'
import styles from './Base.module.scss'
import classNames from 'classnames'
import { _ } from '../../../common/lodash'
import { NavLink } from 'react-router-dom'
import { Loader } from '../Loader'

export interface BaseButtonProps {
    theme?: 'default' | 'theme' | 'transparent'
    onClick?: () => void
    className?: string
    type?: 'reset' | 'submit' | 'button'
    infoAfter?: ReactNode
    active?: boolean
    to?: string
    disabled?: boolean
    alert?: ReactNode
    contentClassName?: string
    loading?: boolean
}

export const BaseButton: FC<PropsWithChildren<BaseButtonProps>> = ({
    children,
    theme,
    onClick,
    className,
    type,
    infoAfter,
    active,
    to,
    disabled,
    alert,
    contentClassName,
    loading,
}) => {
    const baseProps = {
        onClick,
        className: classNames(
            styles.button,
            styles[theme || 'default'],
            { [styles.active]: active, [styles.disabled]: disabled },
            className
        ),
    }

    const internal = (
        <>
            {!_.isUndefined(alert) && (
                <div className={styles.alert}>{alert}</div>
            )}
            <div
                className={classNames(styles.content, contentClassName, {
                    [styles.hidden]: loading,
                })}
            >
                {children}
            </div>
            {loading && <Loader className={styles.loader} />}
            {!_.isUndefined(infoAfter) && (
                <div className={styles.infoAfter}>{infoAfter}</div>
            )}
        </>
    )

    if (!_.isUndefined(to))
        return (
            <>
                {disabled ? (
                    <div {...baseProps}>{internal}</div>
                ) : (
                    <NavLink
                        className={(cl) =>
                            classNames(baseProps.className, {
                                [styles.active]: cl.isActive,
                            })
                        }
                        to={to}
                        onClick={onClick}
                    >
                        {internal}
                    </NavLink>
                )}
            </>
        )

    return (
        <button {...baseProps} type={type || 'button'} disabled={disabled}>
            {internal}
        </button>
    )
}
