import { Fragment, ReactNode } from 'react'
import reactStringReplace from 'react-string-replace'

type TagCallback = (
    attrs: Record<string, string>,
    content?: string
) => JSX.Element

export class CustomTag {
    private tags: Map<string, TagCallback> = new Map()

    add(tag: string, callback: TagCallback): void {
        this.tags.set(tag, callback)
    }

    parse(text: string) {
        const voc = Array.from(this.tags.entries()).reduce<
            (string | ReactNode)[]
        >(
            (voc, [tag, fn]) => {
                const firstReg = new RegExp(
                    `\\[${tag} (.+?)\\](.+?)\\[\\/${tag}\\]`,
                    'gm'
                )
                // eslint-disable-next-line
                const secondReg = /(\w+)="([^\"]+)"/gm

                const config = Array.from(text.matchAll(firstReg)).reduce(
                    (acc, matchArray) => {
                        const [fullTag, rawAttrs, content] = matchArray
                        const attrs = Array.from(rawAttrs.matchAll(secondReg))
                        const objAttrs = Object.fromEntries(
                            attrs.map(([, key, value]) => [key, value])
                        )
                        const result = fn(objAttrs, content)

                        acc.set(fullTag, result)
                        return acc
                    },
                    new Map<string, JSX.Element>()
                )

                return Array.from(config).reduce((acc, [tag, children]) => {
                    return reactStringReplace(acc, tag, (m, i) => (
                        <Fragment key={i}>{children}</Fragment>
                    ))
                }, voc)
            },
            [text]
        )

        return (
            <>
                {Array.from(voc.values()).map((v, i) => (
                    <Fragment key={i}>{v}</Fragment>
                ))}
            </>
        )
    }
}
