import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useGlobalState } from './useGlobalState'

export function usePage(id?: string) {
    const location = useLocation()

    const { state: page, setState: setPage } = useGlobalState<number>(
        `/path/page-${id || '0'}/${location.pathname}`,
        0
    )
    const res = useMemo(() => +page.toString(), [page])

    const set = useCallback((p: number) => setPage(p), [setPage])

    return [res, set] as [number, typeof set]
}
