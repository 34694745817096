import moment from 'moment'
import { Link } from 'react-router-dom'
import { animeAPI } from '../common/api'
import { Anime, Animes } from '../components/Anime/List'
import {
    Block,
    BlockContent,
    BlockInfo,
    BlockLinks,
    BlockLoader,
    BlockTitle,
} from '../components/Block'
import { Pagination } from '../components/UI/Pagination'
import { usePage } from '../hooks/usePage'
import { useTitle } from '../hooks/useTitle'
import { useAppSelector } from '../hooks/useTypedSelector'
import { useCallback } from 'react'
import ms from 'ms'
import { useQuery } from '@tanstack/react-query'
import { useAct } from '../ctx/Error'

export const Index = () => {
    const auth = useAppSelector((s) => s.auth.user)

    const [page, setPage] = usePage()
    const act = useAct()

    const {
        data: recs,
        refetch,
        isLoading,
    } = useQuery([`index/animes/recs`, auth?.id, page + 1], () => {
        if (!auth?.id) return null
        return animeAPI.recs({ page: page + 1 })
    })

    const { data: ongoings } = useQuery(
        ['index/animes/ongoings'],
        () =>
            animeAPI.fetch({
                status: 'ongoing',
                order: 'shikimori_rating',
                order_by: 'desc',
                year_from: +moment().subtract(1, 'year').format('YYYY'),
                limit: 50,
                min_votes: 150,
            }),
        { staleTime: ms('1h') }
    )

    const deleted = useCallback(() => {
        act(async () => {
            const { data } = await refetch()
            if (data?.data.data.length !== 0) return
            setPage(0)
            refetch()
        })
    }, [refetch, setPage, act])

    useTitle({ title: 'Главная страница' })

    return (
        <>
            {!!auth && (
                <>
                    <Block>
                        <BlockTitle>Рекомендации</BlockTitle>

                        {isLoading ? (
                            <BlockLoader />
                        ) : (
                            <>
                                {!recs?.data.data.length && (
                                    <BlockContent>
                                        <BlockInfo>
                                            На данный момент у вас нет
                                            рекомендаций. Новый список
                                            рекомендаций формируется раз в
                                            сутки.
                                        </BlockInfo>
                                    </BlockContent>
                                )}
                            </>
                        )}
                    </Block>
                    {!!recs?.data.data.length && (
                        <div className="animes">
                            {recs.data.data.map(
                                (rec) =>
                                    !!rec.anime && (
                                        <Anime
                                            anime={rec.anime}
                                            rec={rec}
                                            onDeleted={deleted}
                                            key={rec.id}
                                        ></Anime>
                                    )
                            )}
                        </div>
                    )}
                    {recs && (
                        <Pagination
                            data={recs?.data}
                            state={[page, setPage]}
                        ></Pagination>
                    )}
                </>
            )}
            <Block>
                <BlockTitle>Популярные онгоинги</BlockTitle>
            </Block>
            <Animes animes={ongoings?.data.data || []}></Animes>
            <Block>
                <BlockContent>
                    <BlockLinks>
                        <Link to="/info">Дополнительная информация</Link>
                        {!!auth && (
                            <Link to="/recs-deleted">Скрытые рекомендации</Link>
                        )}
                    </BlockLinks>
                </BlockContent>
            </Block>
        </>
    )
}
