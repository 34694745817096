import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { authAPI } from '../common/api'
import { Block, BlockContent, BlockTitle } from '../components/Block'
import { Checkbox } from '../components/UI/Checkbox'
import { Colors } from '../components/UI/Colors'
import { Counter } from '../components/UI/Counter'
import { Croppie } from '../components/UI/Croppie'
import {
    Form,
    FormButtons,
    FormElement,
    FormHalf,
    FormLabel,
} from '../components/UI/Forms'
import { Input } from '../components/UI/Input/Input'
import { Select } from '../components/UI/Select'
import { useTitle } from '../hooks/useTitle'
import { useAppSelector } from '../hooks/useTypedSelector'
import { IUser } from '../types/user'
import { Button } from '../components/UI/Button/Button'
import { useAct } from '../ctx/Error'
import { Textarea } from '../components/UI/Input/Textarea'
import { authSlice } from '../store/slices/auth'
import { useLocalStorages } from '../hooks/useLocalStorages'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useFormikInput } from '../hooks/useFormikInput'
import { AuthValidation } from '../common/validation'

export const Settings = () => {
    const {
        darkStorage,
        themeStorage,
        chartDateCountStorage,
        showChartStorage,
        activeTransitionsStorage,
    } = useLocalStorages()

    const [dark, setDark] = darkStorage
    const auth = useAppSelector((s) => s.auth.user)
    const [theme, setTheme] = themeStorage

    const [chartDateCount, setChartDateCount] = chartDateCountStorage
    const [showChart, setShowChart] = showChartStorage
    const [activeTransitions, setActiveTransitions] = activeTransitionsStorage

    const optimize = useMemo(
        () => !showChart && !activeTransitions,
        [showChart, activeTransitions]
    )

    function setOptimization() {
        setShowChart(optimize ? true : false)
        setActiveTransitions(optimize ? true : false)
    }

    useTitle({ title: 'Настройки' })

    return (
        <>
            <Block>
                <BlockTitle>Настройки приложения</BlockTitle>
                <BlockContent>
                    <Form>
                        <FormElement>
                            <Checkbox
                                state={[dark, () => setDark(!dark)]}
                                title="Темная тема"
                            ></Checkbox>
                        </FormElement>
                        <FormLabel>
                            <span>Основная тема</span>
                        </FormLabel>
                        <FormElement>
                            <Colors
                                colors={[
                                    ['default', '#4d65ac'],
                                    ['green', '#58ac4d'],
                                    ['red', '#ac4d4d'],
                                    ['orange', '#ac7c4d'],
                                    ['dark-theme', '#282828'],
                                    ['light-theme', '#e4e7ed'],
                                    ['purple', '#8a5b8a'],
                                ]}
                                value={theme}
                                onChange={(theme) => setTheme(theme)}
                            ></Colors>
                        </FormElement>
                    </Form>
                </BlockContent>
            </Block>
            <Block>
                <BlockTitle>Настройки оптимизации</BlockTitle>
                <BlockContent>
                    <Form>
                        <FormLabel>
                            <span>
                                Данный режим отключает все дополнительные
                                функции
                            </span>
                        </FormLabel>
                        <Checkbox
                            state={[optimize, () => setOptimization()]}
                            title="Режим оптимизации"
                        ></Checkbox>
                        <FormLabel>
                            <span>
                                Активированные графики требуют вычислительные
                                мощности вашего устройства при отрисовке
                            </span>
                        </FormLabel>
                        <Checkbox
                            state={[showChart, () => setShowChart(!showChart)]}
                            title="Графики"
                        ></Checkbox>
                        {showChart && (
                            <>
                                <FormLabel>
                                    Количество дней в графике активности <br />
                                    <span>
                                        Чем выше значение, тем больше времени
                                        потребуется на обработку данных вашим
                                        устройством. (Помимо этого, на скорость
                                        отрисовки влияет количество данных)
                                    </span>
                                </FormLabel>
                                <Counter
                                    min={2}
                                    max={30}
                                    value={chartDateCount}
                                    onChange={(e) => setChartDateCount(e)}
                                ></Counter>
                            </>
                        )}
                        {/* <FormLabel>
                        <span>
                            Отключая эту опцию, вы не сможете увидеть списки
                            других пользователей на определенном аниме, но
                            сможете сэкономить ресурсы вашего устройства
                        </span>
                    </FormLabel> */}
                        {/* <Checkbox
                        state={[
                            showAnimeListCount,
                            () =>
                                dispatch(
                                    actions.showAnimeListCount(
                                        !showAnimeListCount
                                    )
                                ),
                        ]}
                        title="Количество списков"
                    ></Checkbox> */}
                        <FormLabel>
                            <span>
                                Отключение анимаций позволяет ускорить работу
                                приложения на слабых устройствах
                            </span>
                        </FormLabel>
                        <Checkbox
                            state={[
                                activeTransitions,
                                () => setActiveTransitions(!activeTransitions),
                            ]}
                            title="Анимации интерфейса"
                        ></Checkbox>
                    </Form>
                </BlockContent>
            </Block>
            {!!auth && <ProfileSettings user={auth}></ProfileSettings>}
        </>
    )
}

export const ProfileSettings: FC<{ user: IUser }> = ({ user }) => {
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: user.name,
            city: user.city || '',
            description: user.description || '',
            password: '',
            confirmationPassword: '',
            gender: user.gender || 'null',
        },
        validationSchema: Yup.object({
            name: AuthValidation.name,
            city: AuthValidation.city,
            description: AuthValidation.description,
            password: AuthValidation.password.optional(),
            confirmationPassword: AuthValidation.password.optional(),
        }),
        validate(values) {
            let errors: Record<string, string> = {}

            if (values.password !== values.confirmationPassword) {
                errors.confirmationPassword = 'Пароли не совпадают'
            }

            return errors
        },
        onSubmit: async ({
            password,
            confirmationPassword,
            gender,
            ...values
        }) => {
            setLoading(true)

            try {
                await act(async () => {
                    if (newAvatar) await authAPI.updateAvatar(newAvatar)
                    const auth = await authAPI.update({
                        password: password.length > 0 ? password : undefined,
                        gender: gender === 'null' ? null : gender,
                        ...values,
                    })
                    toast('Сохранено!')
                    dispatch(authSlice.actions.setUser(auth.data))
                })
            } catch (error) {
                console.error(error)
            }

            setLoading(false)
        },
    })

    const dispatch = useDispatch()
    const [newAvatar, setNewAvatar] = useState<File>()
    const [t] = useTranslation()
    const act = useAct()

    const formikName = useFormikInput(formik, 'name')
    const formikCity = useFormikInput(formik, 'city')
    const formikDescription = useFormikInput(formik, 'description')
    const formikPassword = useFormikInput(formik, 'password')
    const formikConfirmationPassword = useFormikInput(
        formik,
        'confirmationPassword'
    )

    async function logout() {
        await authAPI.logout()
        dispatch(authSlice.actions.setUser(null))
        dispatch(authSlice.actions.setList([]))
        dispatch(authSlice.actions.setNotifications(0))
    }

    return (
        <Block>
            <BlockTitle>Настройки профиля</BlockTitle>
            <BlockContent>
                <Form onSubmit={formik.submitForm}>
                    <FormLabel>Никнейм</FormLabel>
                    <FormElement>
                        <Input {...formikName} disabled={loading} />
                    </FormElement>

                    <FormLabel>Город</FormLabel>
                    <FormElement>
                        <Input {...formikCity} disabled={loading} />
                    </FormElement>

                    <FormLabel>Пол</FormLabel>
                    <FormElement>
                        <Select
                            options={[
                                ['null', t(`user.gender.null`)],
                                ['male', t(`user.gender.male`)],
                                ['female', t(`user.gender.female`)],
                            ]}
                            value={formik.values.gender}
                            onChange={(e) =>
                                formik.setFieldValue('gender', e || 'null')
                            }
                        ></Select>
                    </FormElement>

                    <FormLabel>Описание</FormLabel>
                    <FormElement>
                        <Textarea {...formikDescription} disabled={loading} />
                    </FormElement>

                    <FormElement>
                        <FormHalf>
                            <FormLabel>Новый пароль</FormLabel>
                            <Input
                                {...formikPassword}
                                type="password"
                                disabled={loading}
                            />
                        </FormHalf>
                        <FormHalf>
                            <FormLabel>Подтвердите пароль</FormLabel>
                            <Input
                                {...formikConfirmationPassword}
                                type="password"
                                disabled={loading}
                            />
                        </FormHalf>
                    </FormElement>

                    <FormLabel>
                        Новый аватар <br />{' '}
                        <span>
                            Внимание! Новый аватар можно устанавливать раз в
                            месяц
                        </span>
                    </FormLabel>

                    <FormElement>
                        <Croppie onFile={(f) => setNewAvatar(f)}></Croppie>
                    </FormElement>

                    <FormButtons>
                        <Button
                            theme="theme"
                            type="button"
                            onClick={logout}
                            disabled={loading}
                        >
                            Выйти
                        </Button>
                        <Button
                            theme="theme"
                            type="button"
                            onClick={formik.resetForm}
                            disabled={loading}
                        >
                            Сбросить
                        </Button>
                        <Button
                            theme="theme"
                            type="submit"
                            loading={loading}
                            disabled={loading}
                        >
                            Сохранить
                        </Button>
                    </FormButtons>
                </Form>
            </BlockContent>
        </Block>
    )
}
