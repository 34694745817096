import { useFormik } from 'formik'
import { ChangeEvent } from 'react'

export const useFormikInput = <T extends Object>(
    formik: ReturnType<typeof useFormik<T>>,
    field: keyof T
) => {
    return {
        value: formik.values[field],
        onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            formik.setFieldValue(field as string, e.target.value),
        msg: formik.errors[field]?.toString(),
        error: formik.submitCount > 0,
        name: field,
    }
}
