import { FC, PropsWithChildren } from 'react'
import { BaseButton, BaseButtonProps } from './Base'
import classNames from 'classnames'
import styles from './Icon.module.scss'

export interface IconButtonProps extends BaseButtonProps {
    size?: 'normal'
}

export const IconButton: FC<PropsWithChildren<IconButtonProps>> = ({
    className,
    size,
    ...props
}) => {
    return (
        <BaseButton
            className={classNames(
                styles.button,
                styles[size || 'normal'],
                className
            )}
            {...props}
        />
    )
}
