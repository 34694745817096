import classNames from 'classnames'
import { FC } from 'react'
import { State } from '../../types/app'

export const Checkbox: FC<{
    title?: string
    state: State<boolean>
}> = ({ state, title }) => {
    const [checked, setChecked] = state

    return (
        <div className="checkbox">
            <div
                className="checkbox__body"
                onClick={() => setChecked((old) => !old)}
            >
                <div
                    className={classNames([
                        'checkbox__point',
                        checked ? 'checkbox__point-checked' : '',
                    ])}
                ></div>
            </div>
            {!!title && <div className="checkbox__title">{title}</div>}
        </div>
    )
}
