import { FC, PropsWithChildren, useState } from 'react'
import { getUserAvatar } from '../../common/mappers/users'
import { useAppSelector } from '../../hooks/useTypedSelector'
import { Popup } from '../Popup'
import { Img } from '../UI/Img'
import { IconButton, IconButtonProps } from '../UI/Button/Icon'
import classNames from 'classnames'
import { NavbarRegister } from './module/Register'
import { NavbarAuthFields, NavbarPages } from './types'
import { NavbarLogin } from './module/Login'
import { NavbarForgotPassword } from './module/ForgotPassword'
import { NavbarConfirmForgotPassword } from './module/ConfirmForgotPassword'
import { useTranslation } from 'react-i18next'

const NavbarButton: FC<
    PropsWithChildren<IconButtonProps & { title: string }>
> = ({ title, theme, className, ...props }) => {
    return (
        <div className="navbar__item" data-title={title}>
            <IconButton
                className={classNames('navbar__button', className)}
                theme={theme || 'theme'}
                {...props}
            />
        </div>
    )
}

export const Navbar = () => {
    const [show, setShow] = useState(false)
    const [page, setPage] = useState<NavbarPages>('login')
    const authValues = useState<NavbarAuthFields>({
        email: '',
        password: '',
        confPassword: '',
        key: '',
    })

    const auth = useAppSelector((s) => s.auth.user)
    const notifies = useAppSelector((s) => s.auth.notifications)
    const [t] = useTranslation()

    return (
        <>
            <div className="navbar">
                {!!auth ? (
                    <NavbarButton
                        to="/profile"
                        title={auth?.name}
                        alert={notifies === 0 ? undefined : notifies}
                    >
                        <Img src={getUserAvatar(auth)}></Img>
                    </NavbarButton>
                ) : (
                    <NavbarButton
                        onClick={() => setShow(true)}
                        title={t(`navbarpage.${page}`)}
                        alert={notifies === 0 ? undefined : notifies}
                    >
                        <i className="fa fa-user"></i>
                    </NavbarButton>
                )}
                <NavbarButton to="/" title="Главная">
                    <i className="fa fa-home"></i>
                </NavbarButton>
                <NavbarButton to="/anime" title="Аниме">
                    <i className="fas fa-th-list"></i>
                </NavbarButton>
                {/* <NavLink
                    className="navbar__item"
                    data-title="Пользователи"
                    to="/users"
                    >
                    <i className="fa-solid fa-users"></i>
                </NavLink> */}
                <NavbarButton to="/settings" title="Настройки">
                    <i className="fas fa-sliders-h"></i>
                </NavbarButton>
                {auth?.role === 'admin' && (
                    <NavbarButton to="/admin" title="Админ">
                        <i className="fa-solid fa-screwdriver-wrench"></i>
                    </NavbarButton>
                )}
            </div>
            {!auth && (
                <Popup
                    state={[show, setShow]}
                    title={t(`navbarpage.${page}`) || undefined}
                >
                    {page === 'login' && (
                        <NavbarLogin
                            values={authValues}
                            onChangePage={setPage}
                            onClose={() => setShow(false)}
                        />
                    )}
                    {page === 'register' && (
                        <NavbarRegister
                            values={authValues}
                            onChangePage={setPage}
                            onClose={() => setShow(false)}
                        />
                    )}
                    {page === 'forgotPass' && (
                        <NavbarForgotPassword
                            values={authValues}
                            onChangePage={setPage}
                            onClose={() => setShow(false)}
                        />
                    )}
                    {page === 'confirmForgotPassword' && (
                        <NavbarConfirmForgotPassword
                            values={authValues}
                            onChangePage={setPage}
                            onClose={() => setShow(false)}
                        />
                    )}
                </Popup>
            )}
        </>
    )
}
