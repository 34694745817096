import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Container } from './components/Container'
import { Navbar } from './components/Navbar'
import { PageLoader } from './components/UI/Loader'
import { useAppDipatchMods } from './hooks/useTypedDispatch'
import { Router } from './router'
import {
    QueryClient,
    QueryClientProvider,
    useIsFetching,
} from '@tanstack/react-query'
import { ErrorProvider } from './ctx/Error'
import { useLocalStorages } from './hooks/useLocalStorages'

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 0 } },
})

const Fetching: FC<PropsWithChildren> = ({ children }) => {
    const fetching = useIsFetching()

    return (
        <>
            {children}
            {!!fetching && <PageLoader />}
        </>
    )
}

export function Preload() {
    const [loading, setLoading] = useState(true)
    const { fetchAuth, fetchMetaData } = useAppDipatchMods()
    const { darkStorage, themeStorage, activeTransitionsStorage } =
        useLocalStorages()
    const [dark] = darkStorage
    const [theme] = themeStorage
    const [activeTransitions] = activeTransitionsStorage

    useEffect(() => {
        dark
            ? document.body.classList.add('dark')
            : document.body.classList.remove('dark')
    }, [dark])

    useEffect(() => {
        document.body.classList.add(theme)
        return () => document.body.classList.remove(theme)
    }, [theme])

    useEffect(() => {
        activeTransitions
            ? document.body.classList.remove('no-transitions')
            : document.body.classList.add('no-transitions')
    }, [activeTransitions])

    const init = useCallback(async () => {
        try {
            await fetchAuth()
            fetchMetaData()
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }, [fetchAuth, fetchMetaData])

    useEffect(() => {
        init()
    }, [init])

    return loading ? <PageLoader /> : <App />
}

export function App() {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <Fetching>
                    <ErrorProvider>
                        <div className="app">
                            <Container>
                                <Router></Router>
                            </Container>
                            <Navbar></Navbar>
                        </div>
                    </ErrorProvider>
                </Fetching>
            </QueryClientProvider>
        </BrowserRouter>
    )
}
