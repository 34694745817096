import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router'
import { PageLoader } from '../components/UI/Loader'
import { AnimePage } from '../pages/Anime'
import { AnimeDetailPage } from '../pages/AnimeDetail'
import { Index } from '../pages/Index'
import { InfoPage } from '../pages/Info'
import { NotificationsPage } from '../pages/Notifications'
import { ProfilePage } from '../pages/Profile'
import { RecsDeletedPage } from '../pages/RecsDeleted'
import { Settings } from '../pages/Settings'
import { AdminMiddleware } from './middlewares/Admin'
import { AuthMiddleware } from './middlewares/Auth'

import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

const LazyAdminPage = lazy(() => import('../pages/Admin'))

export const Router = () => {
    return (
        <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Routes>
                <Route
                    path="/admin"
                    element={
                        <AdminMiddleware>
                            <Suspense fallback={<PageLoader />}>
                                <LazyAdminPage></LazyAdminPage>
                            </Suspense>
                        </AdminMiddleware>
                    }
                />
                <Route path="/about"></Route>
                <Route path="/settings" element={<Settings />} />
                <Route path="/anime" element={<AnimePage />} />
                <Route path="/anime/:id" element={<AnimeDetailPage />} />
                <Route
                    path="/profile"
                    element={
                        <AuthMiddleware>
                            <ProfilePage></ProfilePage>
                        </AuthMiddleware>
                    }
                />
                <Route
                    path="/notifications"
                    element={
                        <AuthMiddleware>
                            <NotificationsPage></NotificationsPage>
                        </AuthMiddleware>
                    }
                />
                <Route path="/info" element={<InfoPage />} />
                <Route
                    path="/recs-deleted"
                    element={
                        <AuthMiddleware>
                            <RecsDeletedPage />
                        </AuthMiddleware>
                    }
                />

                {/* <Route path="/users/:id">
                <UserPage />
            </Route>

            <Route exact path="/users">
                <UsersPage />
            </Route> */}

                <Route path="/" element={<Index />} />
            </Routes>
        </QueryParamProvider>
    )
}
