import { useMemo } from 'react'
import { useAppSelector } from './useTypedSelector'

export function useMappedSelector() {
    const list = useAppSelector((s) => s.auth.list)
    const mapListByAnimeId = useMemo(
        () => new Map(list.map((l) => [l.anime_id, l])),
        [list]
    )

    return useMemo(() => ({ mapListByAnimeId }), [mapListByAnimeId])
}
