import { ChangeEvent, FC } from 'react'
import { InputContainer, InputContainerProps } from './Container'
import styles from './Input.module.scss'

export interface TextareaProps extends InputContainerProps {
    value?: string
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
    disabled?: boolean
}

export const Textarea: FC<TextareaProps> = ({
    value,
    onChange,
    error,
    msg,
    disabled,
}) => {
    const containerProps = {
        error,
        msg,
    }

    const textareaProps = {
        value,
        onChange,
        disabled,
    }

    return (
        <InputContainer {...containerProps}>
            <textarea {...textareaProps} className={styles.field} />
        </InputContainer>
    )
}
