import { FC, useEffect, useState } from 'react'
import { images } from '../../assets/assets'

export const Img: FC<
    React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    >
> = (props) => {
    const [src, setSrc] = useState(props.src)

    useEffect(() => {
        setSrc(props.src)
    }, [props.src])

    return (
        <img
            {...props}
            onError={() => setSrc(images.unknown)}
            src={src}
            alt={props.alt || ''}
        />
    )
}
