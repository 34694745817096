import { FC } from 'react'
import { _ } from '../../common/lodash'
import { IconButton } from './Button/Icon'

export const Counter: FC<{
    value: number
    onChange: (value: number) => void
    min?: number
    max?: number
}> = ({ value, onChange, min, max }) => {
    const finalValue = _.isNaN(value) ? 0 : value
    function plus() {
        if (!_.isUndefined(max) && finalValue >= max) return
        onChange(finalValue + 1)
    }

    function minus() {
        if (!_.isUndefined(min) && finalValue <= min) return
        onChange(finalValue - 1)
    }

    return (
        <div className="counter input">
            <IconButton onClick={minus}>
                <i className="fa-solid fa-minus"></i>
            </IconButton>
            <div className="counter__field">{finalValue}</div>
            <IconButton onClick={plus}>
                <i className="fa-solid fa-plus"></i>
            </IconButton>
        </div>
    )
}
