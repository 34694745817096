import { ListStatus } from '../../types/list'

export const rui18n = {
    type: {
        tv: 'Сериал',
        movie: 'Фильм',
        ona: 'ONA',
        special: 'Спэшл',
        tv_13: 'Сериал 13',
        music: 'Клип',
        tv_24: 'Сериал 24',
        tv_48: 'Сериал 48',
        ova: 'OVA',
    },
    status: {
        ongoing: 'Онгоинг',
        anons: 'Анонс',
        released: 'Вышел',
    },
    listStatus: {
        [ListStatus.look]: 'Смотрю',
        [ListStatus.scanned]: 'Просмотрено',
        [ListStatus.scheduled]: 'Запланировано',
        [ListStatus.review]: 'Пересматриваю',
        [ListStatus.forsaken]: 'Брошено',
        [ListStatus.follow]: 'Любимое',
    },
    user: {
        gender: {
            male: 'Мужской',
            female: 'Женский',
            null: 'Не выбрано',
        },
        role: {
            admin: 'Администратор',
            user: 'Пользователь',
        },
    },
    sort: {
        asc: 'По возрастанию',
        desc: 'По убыванию',
        title: 'По названию',
        shikimori_rating: 'По рейтингу',
        release_at: 'По дате выхода',
    },
    navbarpage: {
        login: 'Вход',
        register: 'Регистрация',
        forgotPass: 'Восстановление доступа',
        confirmForgotPassword: 'Подтверждение входа',
    },
}
