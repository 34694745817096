import _ from 'lodash'

export function fromFileToBase64(file: File) {
    return new Promise<string | null>((res, rej) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            const result = reader.result
            if (!_.isString(result)) return res(null)
            res(result)
        }
        reader.onerror = (e) => {
            rej(e)
        }
    })
}

export function createFormData(data: object) {
    const form = new FormData()
    Object.entries(data).forEach(([key, value]) => form.append(key, value))
    // form.append('_method', method || 'post')
    return form
}

export const copy = (text: string) => {
    const i = document.createElement('input')
    i.value = text
    document.body.append(i)
    i.select()
    document.execCommand('copy')
    i.remove()
}

export const makeRegExp = (val: string) =>
    new RegExp(_.escapeRegExp(val), 'gim')

export const switchArray = <T>(array: T[], value: T): T[] => {
    const set = new Set(array)
    if (set.has(value)) {
        set.delete(value)
        return Array.from(set)
    }
    set.add(value)
    return Array.from(set)
}
