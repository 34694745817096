import { FC, useEffect, useRef, useState } from 'react'
import BaseSelect from '@mui/base/Select'
import Option from '@mui/base/Option'
import BasePoper from '@mui/base/Popper'
import { _ } from '../../common/lodash'

type value = string
type label = string

type ISelectOption = [value, label]

export const Select: FC<{
    options: ISelectOption[]
    value: string | null
    onChange?: (values: string | null) => void
}> = ({ options, value, onChange }) => {
    const middle = (vals: string[]) => {
        if (onChange) {
            const v = _.first(vals.filter((v) => v !== value))
            if (!v) return onChange(null)
            onChange(v)
        }
    }

    return (
        <SelectMultiple
            options={options}
            values={value ? [value] : []}
            onChange={middle}
        ></SelectMultiple>
    )
}

export const SelectMultiple: FC<{
    options: ISelectOption[]
    values: string[]
    onChange?: (values: string[]) => void
}> = ({ options, values, onChange }) => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    const buttonRef = useRef<HTMLButtonElement>(null)
    const poperRef = useRef<HTMLDivElement>(null)

    const computeSizes = () => {
        const { current: button } = buttonRef
        if (!button) return

        const buttonRect = button.getBoundingClientRect()
        setWidth(buttonRect.width)

        const { current: poper } = poperRef
        if (!poper) return
        const poperRect = poper.getBoundingClientRect()
        setHeight(window.innerHeight - poperRect.top)
    }

    useEffect(() => {
        const resize = () => computeSizes()

        window.addEventListener('resize', resize)
        return () => window.removeEventListener('resize', resize)
    }, [])

    const change = (values: string[]) => {
        onChange && onChange(values)
    }

    return (
        <BaseSelect
            ref={buttonRef}
            value={values}
            className="select"
            multiple
            slots={{
                popper: (props) => (
                    <BasePoper
                        ref={poperRef}
                        {...props}
                        style={{ width, maxHeight: height }}
                    />
                ),
            }}
            onChange={(...e) => change(e[1])}
            renderValue={(values) => (
                <div className="select__values">
                    {values.map((value) => (
                        <div className="select__value" key={value.id}>
                            {value.label}
                        </div>
                    ))}
                </div>
            )}
            onListboxOpenChange={computeSizes}
        >
            {options.map(([value, label]) => (
                <Option value={value} key={value} className="select__option">
                    {label}
                </Option>
            ))}
        </BaseSelect>
    )
}
