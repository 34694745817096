import { useQuery } from '@tanstack/react-query'
import { authAPI } from '../common/api'
import { Notifications } from '../components/Notifications'
import { Pagination } from '../components/UI/Pagination'
import { usePage } from '../hooks/usePage'
import { useTitle } from '../hooks/useTitle'
import { Block, BlockContent, BlockInfo } from '../components/Block'

export const NotificationsPage = () => {
    const [page, setPage] = usePage()

    const { data, refetch, isFetched } = useQuery(['/notifies', page + 1], () =>
        authAPI.notifications.list({ page: page + 1 })
    )

    useTitle({ title: 'Уведомления' })

    return (
        <>
            {isFetched && (
                <>
                    {data?.data.data.length ? (
                        <Notifications
                            notifies={data?.data.data}
                            onRead={refetch}
                            onDelete={refetch}
                        ></Notifications>
                    ) : (
                        <Block>
                            <BlockContent>
                                <BlockInfo>
                                    Ваш список уведомлений на данный момент
                                    пуст.
                                </BlockInfo>
                            </BlockContent>
                        </Block>
                    )}
                </>
            )}
            {data && (
                <Pagination
                    data={data.data}
                    state={[page, setPage]}
                ></Pagination>
            )}
        </>
    )
}
