import {
    FC,
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useState,
} from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '../components/UI/Dialog'
import { AxiosError } from 'axios'
import { Button } from '../components/UI/Button/Button'
import { useDispatch } from 'react-redux'
import { LS } from '../common/localStorage'
import { _ } from '../common/lodash'
import { authSlice } from '../store/slices/auth'

type ActFunction = <T>(fn: () => Promise<T>) => Promise<T>

const ErrorActContext = createContext<ActFunction>((fn) => fn())

export const useAct = () => useContext(ErrorActContext)

export const ErrorProvider: FC<PropsWithChildren> = ({ children }) => {
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const dispatch = useDispatch()

    const act: ActFunction = useCallback(
        async (fn) => {
            try {
                const result = await fn()
                return result
            } catch (error) {
                console.error(error)

                if (error instanceof Error) {
                    setShowError(true)
                    setError(error.message)
                }

                if (error instanceof AxiosError) {
                    setShowError(true)
                    const status = error.response?.status

                    if (!status) {
                        setError('Неизвестная ошибка!')
                    }

                    if (status === 401) {
                        setError('Ошибка авторизации! Войдите в свой аккаунт.')
                        dispatch(authSlice.actions.setUser(null))
                        dispatch(authSlice.actions.setNotifications(0))
                        dispatch(authSlice.actions.setList([]))
                        LS.token.remove()
                    }

                    if (status === 422) {
                        const data = error.response?.data.data
                        if (_.isObject(data)) {
                            const messages = Object?.entries(data)
                                ?.map(([, value]: any) => value)
                                ?.join('\n .')
                            setError(messages)
                        }
                    }

                    if (status?.toString().startsWith('50')) {
                        setError('Произошла критическая ошибка сервера!')
                    }
                }

                throw error
            }
        },
        [dispatch]
    )

    return (
        <ErrorActContext.Provider value={act}>
            {children}
            <Dialog open={showError}>
                <DialogTitle>Ошибка!</DialogTitle>
                <DialogContent>{error}</DialogContent>
                <DialogActions>
                    <Button theme="theme" onClick={() => setShowError(false)}>
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </ErrorActContext.Provider>
    )
}
