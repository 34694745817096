export enum AppThemes {
    standart = 'standart',
}

export const LS = {
    dark: {
        get(): boolean {
            return localStorage.getItem('dark') === 'true'
        },
        set(status: boolean) {
            localStorage.setItem('dark', `${status}`)
        },
    },
    theme: {},
    token: {
        get() {
            return localStorage.getItem('token')
        },
        set(token: string) {
            return localStorage.setItem('token', token)
        },
        remove() {
            localStorage.removeItem('token')
        },
    },
}
