import classNames from 'classnames'
import React, { DragEvent, FC, useEffect, useRef, useState } from 'react'
import { images } from '../../assets/assets'
import { fromFileToBase64 } from '../../common/utils'
import { State } from '../../types/app'
import { Img } from './Img'

export const FileUI: FC<{
    state: State<File | undefined>
    onChange?: (file: File | undefined) => void
    accept?: string
}> = ({ onChange, state, accept }) => {
    const f = useRef<HTMLInputElement>(null)
    const [file, setFile] = state
    const [preview, setPreview] = useState<string | undefined>()
    const [drag, setDrag] = useState(false)

    async function handler(e: React.ChangeEvent<HTMLInputElement>) {
        const [file] = Array.from(e.target.files || [])
        if (!file) return
        change(file)
    }

    async function change(file: File | undefined) {
        setFile(file)
        if (onChange) onChange(file)
    }

    useEffect(() => {
        if (!file) return setPreview(undefined)
        const s = async () => {
            const base64 = await fromFileToBase64(file)
            if (!base64) return
            setPreview(base64)
        }
        s()
    }, [file])

    function dragStartHandler(e: DragEvent<HTMLDivElement>) {
        e.preventDefault()
        if (!drag) setDrag(true)
    }
    function dragLeaveHandler(e: DragEvent<HTMLDivElement>) {
        e.preventDefault()
        setDrag(false)
    }

    async function dropHandler(e: DragEvent<HTMLDivElement>) {
        e.preventDefault()
        setDrag(false)
        const [file] = Array.from(e.dataTransfer.files || [])
        if (!file) return
        change(file)
    }

    function del(e: React.MouseEvent<HTMLDivElement>) {
        e.preventDefault()
        e.stopPropagation()
        change(undefined)
    }

    return (
        <div className={classNames('file', 'input', { file_active: drag })}>
            <div
                className="file__inner input__field"
                onClick={() => f.current?.click()}
                onDragStart={dragStartHandler}
                onDragLeave={dragLeaveHandler}
                onDragOver={dragStartHandler}
                onDrop={dropHandler}
            >
                {preview ? (
                    <Img
                        className="file__preview"
                        src={preview || images.file}
                    ></Img>
                ) : (
                    <i className="file__preview fa-solid fa-file"></i>
                )}
                <div className="file__body">
                    <div className="file__str">
                        {file?.name || 'Выберете файл или перенесите'}
                    </div>
                    {!!file && (
                        <div className="file__str ">
                            <div
                                className="file__str-red file__str-underline"
                                onClick={del}
                            >
                                Удалить
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <input type="file" accept={accept} ref={f} onChange={handler} />
        </div>
    )
}
