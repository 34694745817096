import { FC } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useLocalStorages } from '../hooks/useLocalStorages'

export const Captcha: FC<{ onToken: (token: string | null) => void }> = ({
    onToken,
}) => {
    const { darkStorage } = useLocalStorages()
    const [darkTheme] = darkStorage

    return (
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC || ''}
            theme={darkTheme ? 'dark' : 'light'}
            size="compact"
            onChange={onToken}
        />
    )
}
