import { FC, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { authAPI } from '../common/api'
import { CustomTag } from '../common/customTag'
import { useAppSelector } from '../hooks/useTypedSelector'
import { INotify } from '../types/notify'
import { Block, BlockContent, BlockTitle } from './Block'
import { Link } from 'react-router-dom'
import { useAct } from '../ctx/Error'
import { IconButton } from './UI/Button/Icon'
import { authSlice } from '../store/slices/auth'

export const Notifications: FC<{
    notifies: INotify[]
    onDelete?: () => void
    onRead?: () => void
}> = ({ notifies, onRead, onDelete }) => {
    return (
        <Block>
            <BlockTitle>Уведомления</BlockTitle>
            <BlockContent>
                <div className="notifications">
                    {notifies.map((n) => (
                        <Notification
                            notify={n}
                            key={n.id}
                            onDelete={onDelete}
                            onRead={onRead}
                        ></Notification>
                    ))}
                </div>
            </BlockContent>
        </Block>
    )
}

export const Notification: FC<{
    notify: INotify
    onDelete?: () => void
    onRead?: () => void
}> = ({ notify, onDelete, onRead }) => {
    const dispatch = useDispatch()
    const count = useAppSelector((s) => s.auth.notifications)
    const act = useAct()

    function read() {
        act(async () => {
            await authAPI.notifications.read(notify.id)
            dispatch(authSlice.actions.setNotifications(count - 1))
            if (onDelete) onDelete()
        })
    }
    function del() {
        act(async () => {
            const active = notify.active
            await authAPI.notifications.delete(notify.id)
            if (!active) dispatch(authSlice.actions.setNotifications(count - 1))
            if (onRead) onRead()
        })
    }

    const text = useMemo(() => {
        const { text } = notify
        if (!text) return

        const tags = new CustomTag()

        tags.add('anime_link', (attrs, content) => {
            return <Link to={`/anime/${attrs.id}`}>{content}</Link>
        })

        return tags.parse(text)
    }, [notify])

    return (
        <div className="notification">
            <div className="notification__text">{text}</div>
            {!notify.active && (
                <IconButton theme="transparent" onClick={read}>
                    <i className="fa-solid fa-eye"></i>
                </IconButton>
            )}
            <IconButton theme="transparent" onClick={del}>
                <i className="fa-solid fa-trash"></i>
            </IconButton>
        </div>
    )
}
