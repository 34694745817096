import { FC, PropsWithChildren } from 'react'
import styles from './Dialog.module.scss'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

export const Dialog: FC<PropsWithChildren<{ open: boolean }>> = ({
    children,
    open,
}) => {
    return createPortal(
        <div className={classNames(styles.dialog, { [styles.open]: open })}>
            <div className={classNames(styles.bg, { [styles.open]: open })} />
            <div className={classNames(styles.card, { [styles.open]: open })}>
                {children}
            </div>
        </div>,
        document.getElementById('dialog')!
    )
}

export const DialogTitle: FC<PropsWithChildren> = ({ children }) => {
    return <div className={styles.title}>{children}</div>
}

export const DialogContent: FC<PropsWithChildren> = ({ children }) => {
    return <div className={styles.content}>{children}</div>
}

export const DialogActions: FC<PropsWithChildren> = ({ children }) => {
    return <div className={styles.actions}>{children}</div>
}
