import classNames from 'classnames'
import { FC, PropsWithChildren, useState } from 'react'

export const Text: FC<PropsWithChildren> = ({ children }) => {
    return <div className="text">{children}</div>
}

export const Tab: FC<PropsWithChildren<{ title: string }>> = ({
    title,
    children,
}) => {
    const [active, setActive] = useState(false)
    return (
        <div className="text-tab">
            <div
                className="text-tab__title"
                onClick={() => setActive((o) => !o)}
            >
                {title}
            </div>
            <pre
                className={classNames('text-tab__body', {
                    'text-tab__body-active': active,
                })}
            >
                {children}
            </pre>
        </div>
    )
}
