import { IAnime } from './anime'

export interface IList {
    id: number
    user_id: number
    anime_id: number
    anime?: IAnime
    status: ListStatus | null
    follow: boolean
    created_at: string
    updated_at: string
}

export enum ListStatus {
    look = 'look',
    scanned = 'scanned',
    scheduled = 'scheduled',
    review = 'review',
    forsaken = 'forsaken',
    follow = 'follow',
}
