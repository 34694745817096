import { FC, useCallback, useEffect, useRef, useState } from 'react'
import CroppieClass from 'croppie'
import { fromFileToBase64 } from '../../common/utils'
import { Popup } from '../Popup'
import { FormButtons } from './Forms'
import { FileUI } from './File'
import { Button } from './Button/Button'

export const Croppie: FC<{
    onFile?: (file: File) => void
}> = ({ onFile }) => {
    const canv = useRef<HTMLDivElement>(null)
    const [crop, setCrop] = useState<CroppieClass>()
    const [file, setFile] = useState<File>()
    const [show, setShow] = useState(false)

    const init = useCallback(async () => {
        if (!crop || !file) return
        const url = await fromFileToBase64(file)
        if (!url) return

        crop.bind({
            url,
        })
    }, [file, crop])

    async function result() {
        if (!crop) return
        const res = await crop.result({
            type: 'blob',
            circle: false,
            size: {
                width: 500,
                height: 500,
            },
        })
        setShow(false)
        const newFile = new File([res], file?.name || 'edited')
        if (onFile) onFile(newFile)
        setFile(newFile)
        // if (onBase64) onBase64(res)
    }

    useEffect(() => {
        init()
    }, [init])

    useEffect(() => {
        const { current } = canv
        if (!current) return

        const croppie = new CroppieClass(current, {
            viewport: {
                width: 200,
                height: 200,
            },
            showZoomer: false,
        })

        setCrop(croppie)

        return () => croppie.destroy()
    }, [])

    function onChange(file?: File) {
        setFile(file)
        setShow(!!file)
    }

    return (
        <>
            <FileUI
                accept="image/*"
                state={[file, setFile]}
                onChange={onChange}
            ></FileUI>
            <Popup state={[show, setShow]} title="Обрезать аватар">
                <div ref={canv} style={{ height: 300 }}></div>
                <FormButtons>
                    <Button theme="theme" onClick={() => setShow(false)}>
                        Закрыть
                    </Button>
                    <Button theme="theme" onClick={result}>
                        Обрезать
                    </Button>
                </FormButtons>
            </Popup>
        </>
    )
}
