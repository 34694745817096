import { FC, useEffect, useState } from 'react'
import { authAPI } from '../../../common/api'
import {
    Form,
    FormButtons,
    FormCaptcha,
    FormElement,
    FormLabel,
    FormLink,
} from '../../UI/Forms'
import { Input } from '../../UI/Input/Input'
import { Button } from '../../UI/Button/Button'
import { useFormik } from 'formik'
import { NavbarModuleProps } from '../types'
import { useFormikInput } from '../../../hooks/useFormikInput'
import * as Yup from 'yup'
import { AuthValidation } from '../../../common/validation'
import { Captcha } from '../../Captcha'
import { useAct } from '../../../ctx/Error'

export const NavbarForgotPassword: FC<NavbarModuleProps> = ({
    values,
    onChangePage,
    onClose,
}) => {
    const [obj, setObj] = values
    const [loading, setLoading] = useState(false)
    const [captchaToken, setCaptchaToken] = useState<string | null>(null)

    const act = useAct()

    const formik = useFormik({
        initialValues: obj,
        validationSchema: Yup.object({
            email: AuthValidation.email,
        }),
        onSubmit: async (values) => {
            if (!captchaToken) throw new Error()
            const { email } = values

            setLoading(true)

            try {
                await act(async () => {
                    await authAPI.resetPass.first({
                        email,
                        captcha_token: captchaToken,
                    })
                    // toast('На указанную почту было отправлено письмо')
                    onChangePage('confirmForgotPassword')
                })
            } catch (e) {
                console.error(e)
            }

            setLoading(false)
        },
    })

    const emailFormik = useFormikInput(formik, 'email')

    useEffect(() => {
        setObj(formik.values)
    }, [formik.values, setObj])

    return (
        <Form onSubmit={formik.submitForm}>
            <FormLabel>E-mail</FormLabel>
            <FormElement>
                <Input {...emailFormik} disabled={loading} />
            </FormElement>
            <FormCaptcha>
                <Captcha onToken={setCaptchaToken} />
            </FormCaptcha>
            <FormElement>
                <FormLink onClick={() => onChangePage('login')}>Вход</FormLink>
            </FormElement>
            <FormButtons>
                <Button theme="theme" onClick={onClose} type="button">
                    Закрыть
                </Button>
                <Button
                    theme="theme"
                    type="submit"
                    loading={loading}
                    disabled={loading || !captchaToken}
                >
                    Отправить код
                </Button>
            </FormButtons>
        </Form>
    )
}
