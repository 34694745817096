import classNames from 'classnames'
import { PropsWithChildren, useEffect } from 'react'
import { FC } from 'react'
import { createPortal } from 'react-dom'

export const Popup: FC<
    PropsWithChildren<{
        state: [boolean, (state: boolean) => void]
        title?: string
    }>
> = ({ children, state, title }) => {
    const [show, setShow] = state
    useEffect(() => {
        const classList = document.body.classList
        if (!show) {
            classList.remove('hidden')
        } else {
            classList.add('hidden')
        }
        return () => classList.remove('hidden')
    }, [show])
    return createPortal(
        <div className={classNames('popup', { popup_show: show })}>
            <div
                className={classNames(['popup__bg', show && 'popup__bg-show'])}
                onClick={() => setShow(false)}
            ></div>
            <div
                className={classNames([
                    'popup__inner',
                    show && 'popup__inner-show',
                ])}
            >
                {title && <div className="popup__title">{title}</div>}
                <div className="popup__body">{children}</div>
            </div>
        </div>,
        document.getElementById('popup')!
    )
}
