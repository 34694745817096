import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { authAPI } from '../../../common/api'
import { useAppDipatchMods } from '../../../hooks/useTypedDispatch'
import {
    Form,
    FormButtons,
    FormCaptcha,
    FormDoc,
    FormElement,
    FormLabel,
    FormLink,
} from '../../UI/Forms'
import { Input } from '../../UI/Input/Input'
import { Button } from '../../UI/Button/Button'
import { useFormik } from 'formik'
import { NavbarModuleProps } from '../types'
import { useFormikInput } from '../../../hooks/useFormikInput'
import * as Yup from 'yup'
import { AuthValidation } from '../../../common/validation'
import { Captcha } from '../../Captcha'
import { useAct } from '../../../ctx/Error'

export const NavbarRegister: FC<NavbarModuleProps> = ({
    values,
    onChangePage,
    onClose,
}) => {
    const [obj, setObj] = values
    const { fetchAuth } = useAppDipatchMods()
    const [loading, setLoading] = useState(false)
    const [captchaToken, setCaptchaToken] = useState<string | null>(null)
    const act = useAct()

    const formik = useFormik({
        initialValues: obj,
        validate(values) {
            let error: Record<string, string> = {}

            if (values.password !== values.confPassword) {
                error.confPassword = 'Пароли не совпадают'
            }

            return error
        },
        validationSchema: Yup.object({
            email: AuthValidation.email,
            password: AuthValidation.password,
            confPassword: AuthValidation.password,
        }),
        onSubmit: async (values) => {
            if (!captchaToken) throw new Error()

            const { email, password } = values

            setLoading(true)

            try {
                await act(async () => {
                    await authAPI.register({
                        email,
                        password,
                        captcha_token: captchaToken,
                    })
                    await fetchAuth()
                })
            } catch (e) {
                console.error(e)
            }

            setLoading(false)
        },
    })

    const emailFormik = useFormikInput(formik, 'email')
    const passwordFormik = useFormikInput(formik, 'password')
    const confPasswordFormik = useFormikInput(formik, 'confPassword')

    useEffect(() => {
        setObj(formik.values)
    }, [formik.values, setObj])

    return (
        <Form onSubmit={formik.submitForm}>
            <FormLabel>E-mail</FormLabel>
            <FormElement>
                <Input {...emailFormik} disabled={loading} />
            </FormElement>
            <FormLabel>Пароль</FormLabel>
            <FormElement>
                <Input {...passwordFormik} type="password" disabled={loading} />
            </FormElement>
            <FormLabel>Подтверждение пароля</FormLabel>
            <FormElement>
                <Input
                    {...confPasswordFormik}
                    type="password"
                    disabled={loading}
                />
            </FormElement>
            <FormCaptcha>
                <Captcha onToken={setCaptchaToken} />
            </FormCaptcha>
            <FormElement>
                <FormDoc>
                    При нажатии кнопки "Регистрация" вы подтверждаете, что
                    соглашаетесь с пунктами{' '}
                    <Link to="/info">
                        "Пользовательское соглашение" и "Политика
                        конфиденциальности"
                    </Link>
                </FormDoc>
            </FormElement>
            <FormElement>
                <FormLink onClick={() => onChangePage('login')}>Вход</FormLink>
            </FormElement>
            <FormButtons>
                <Button theme="theme" onClick={onClose} type="button">
                    Закрыть
                </Button>
                <Button
                    loading={loading}
                    disabled={loading || !captchaToken}
                    theme="theme"
                    type="submit"
                >
                    Регистрация
                </Button>
            </FormButtons>
        </Form>
    )
}
