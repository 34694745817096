import isNull from 'lodash/isNull'
import compact from 'lodash/compact'
import round from 'lodash/round'
import isNaN from 'lodash/isNaN'
import isUndefined from 'lodash/isUndefined'
import first from 'lodash/first'
import pickBy from 'lodash/pickBy'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'

export const _ = {
    isNull,
    compact,
    round,
    isNaN,
    isUndefined,
    first,
    pickBy,
    isString,
    isObject,
}
