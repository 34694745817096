import { authSlice } from './slices/auth'
import { metaSlice } from './slices/meta'
import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        meta: metaSlice.reducer,
    },
})
