import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { animeAPI } from '../../common/api'
import { useAnime } from '../../hooks/useAnime'
import { IAnime } from '../../types/anime'
import { State } from '../../types/app'
import { ListStatus } from '../../types/list'
import { Popup } from '.././Popup'
import { Form } from '.././UI/Forms'
import { useTranslation } from 'react-i18next'
import { useMappedSelector } from '../../hooks/useMappedSelector'
import { Button } from '../UI/Button/Button'
import { useAct } from '../../ctx/Error'
import { _ } from '../../common/lodash'
import { authSlice } from '../../store/slices/auth'

export const AnimeListEditor: FC<{ show: State<boolean>; anime: IAnime }> = ({
    anime,
    show,
}) => {
    const lists = Object.values(ListStatus)
    const { list } = useAnime(anime)
    const dispatch = useDispatch()
    const [, setShow] = show
    const { mapListByAnimeId } = useMappedSelector()
    const [t] = useTranslation()
    const act = useAct()

    const updateList = async (status: ListStatus) => {
        setShow(false)
        await act(async () => {
            const { data } = await animeAPI.updateList({
                anime_id: anime.id,
                status,
            })

            if (_.isNull(data.status)) {
                mapListByAnimeId?.delete(data.anime_id)
            } else {
                mapListByAnimeId?.set(data.anime_id, data)
            }

            return dispatch(
                authSlice.actions.setList(Array.from(mapListByAnimeId.values()))
            )
        })
    }

    return (
        <Popup state={show} title="Изменить список">
            <Form>
                {lists.map((l) => (
                    <Button
                        key={l}
                        theme={
                            l === ListStatus.follow
                                ? list?.follow
                                    ? 'default'
                                    : 'theme'
                                : l === list?.status
                                ? 'default'
                                : 'theme'
                        }
                        onClick={() => updateList(l)}
                    >
                        {t(`listStatus.${l}`) || ''}
                    </Button>
                ))}
                <Button theme="transparent" onClick={() => setShow(false)}>
                    Закрыть
                </Button>
            </Form>
        </Popup>
    )
}
