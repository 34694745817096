import classNames from 'classnames'
import { FC } from 'react'

export const Loader: FC<{ className?: string }> = ({ className }) => {
    return (
        <div className={classNames('loader', className)}>
            <span className="baseloader"></span>
        </div>
    )
}

export const PageLoader = () => {
    return (
        <div className="loader_page">
            <Loader />
        </div>
    )
}
