import { FC, useEffect, useRef } from 'react'

import {
    Chart as Ch,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartData,
} from 'chart.js'

Ch.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
)

export const LineChart: FC<{ data: ChartData<'line', number[], string> }> = ({
    data,
}) => {
    const canv = useRef<HTMLCanvasElement>(null)
    useEffect(() => {
        if (!canv.current) return
        const ctx = canv.current.getContext('2d')
        if (!ctx) return

        const c = new Ch(ctx, {
            type: 'line',
            data,
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            precision: 0,
                        },
                    },
                },
                maintainAspectRatio: false,
            },
        })
        return () => c.destroy()
    }, [data])
    return <canvas ref={canv}></canvas>
}

export const BarChart: FC<{ data: ChartData<'bar'> }> = ({ data }) => {
    const canv = useRef<HTMLCanvasElement>(null)
    useEffect(() => {
        if (!canv.current) return
        const ctx = canv.current.getContext('2d')
        if (!ctx) return

        const c = new Ch(ctx, {
            type: 'bar',
            data,
        })
        return () => c.destroy()
    }, [data])
    return <canvas ref={canv}></canvas>
}
