import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { animeAPI } from '../common/api'
import { Animes } from '../components/Anime/List'
import { Popup } from '../components/Popup'
import { ButtonFixed } from '../components/UI/ButtonFixed'
import {
    Form,
    FormButtons,
    FormElement,
    FormHalf,
    FormLabel,
} from '../components/UI/Forms'
import { Input } from '../components/UI/Input/Input'
import { Pagination } from '../components/UI/Pagination'
import { Select, SelectMultiple } from '../components/UI/Select'
import { usePage } from '../hooks/usePage'
import { useTitle } from '../hooks/useTitle'
import { useAppSelector } from '../hooks/useTypedSelector'
import { useQuery } from '@tanstack/react-query'
import {
    useQueryParam,
    ArrayParam,
    NumberParam,
    StringParam,
} from 'use-query-params'
import { Button } from '../components/UI/Button/Button'
import { _ } from '../common/lodash'
import { Block, BlockContent, BlockInfo } from '../components/Block'

export const AnimePage = () => {
    const { genres: metaGenres, types: metaTypes } = useAppSelector(
        (s) => s.meta
    )
    const [t] = useTranslation()

    const genreOptions = useMemo(
        () => metaGenres.map((g) => [g.title, g.title] as [string, string]),
        [metaGenres]
    )
    const typeOptions = useMemo(
        () => metaTypes.map((g) => [g, t(`type.${g}`)] as [string, string]),
        [metaTypes, t]
    )
    const sortOptions = useMemo(
        () =>
            ['title', 'shikimori_rating', 'release_at'].map(
                (val) => [val, t(`sort.${val}`)] as [string, string]
            ),
        [t]
    )
    const sortByOption = useMemo(
        () =>
            ['asc', 'desc'].map(
                (val) => [val, t(`sort.${val}`)] as [string, string]
            ),
        [t]
    )

    const [pickedGenres, setPickedGenres] = useQueryParam('genres', ArrayParam)
    const [pickedTypes, setPickedTypes] = useQueryParam('types', ArrayParam)

    const [page, setPage] = usePage()

    const [showFilter, setShowFilter] = useState(false)

    const [year_from, setYearFrom] = useQueryParam('year_from', NumberParam)
    const [year_to, setYearto] = useQueryParam('year_to', NumberParam)
    const [title, setTitle] = useQueryParam('title', StringParam)
    const [order_by, setOrderBy] = useQueryParam('order_by', StringParam)
    const [order, setOrder] = useQueryParam('order', StringParam)

    const filterData = useMemo(
        () => ({
            year_from,
            year_to,
            title,
            genres: pickedGenres,
            type: pickedTypes,
            order,
            order_by,
        }),
        [year_from, year_to, title, pickedGenres, pickedTypes, order, order_by]
    )

    const [filters, setFilters] = useState(filterData)

    const updateFilter = useCallback(() => {
        setFilters(filterData)
        setPage(0)
    }, [setPage, filterData])

    const { data: animes, isLoading } = useQuery(
        ['animes/list', page + 1, filters],
        () =>
            animeAPI.fetch({
                page: page + 1,
                ..._.pickBy(filters, (v) => !!v),
            })
    )

    useTitle({ title: 'Список аниме' })

    return (
        <>
            {!isLoading && (
                <>
                    {(animes?.data.data.length || 0) > 0 ? (
                        <Animes animes={animes?.data.data || []}></Animes>
                    ) : (
                        <Block>
                            <BlockContent>
                                <BlockInfo>
                                    К сожалению, по вашим фильтрам ничего не
                                    найдено
                                </BlockInfo>
                            </BlockContent>
                        </Block>
                    )}
                </>
            )}
            <ButtonFixed onClick={() => setShowFilter((old) => !old)}>
                <i className="fa-solid fa-filter"></i>
            </ButtonFixed>
            {!!animes && (
                <Pagination
                    data={animes.data}
                    state={[page, setPage]}
                ></Pagination>
            )}
            <Popup state={[showFilter, setShowFilter]} title="Фильтр">
                <Form onSubmit={updateFilter}>
                    <FormLabel>Поиск по названию</FormLabel>
                    <Input
                        value={title?.toString()}
                        onChange={(e) => setTitle(e.target.value)}
                    ></Input>
                    <FormElement>
                        <FormHalf>
                            <FormLabel>Год от</FormLabel>
                            <Input
                                type="number"
                                value={year_from?.toString()}
                                onChange={(e) => setYearFrom(+e.target.value)}
                            ></Input>
                        </FormHalf>
                        <FormHalf>
                            <FormLabel>Год до</FormLabel>
                            <Input
                                type="number"
                                value={year_to?.toString()}
                                onChange={(e) => setYearto(+e.target.value)}
                            ></Input>
                        </FormHalf>
                    </FormElement>
                    <FormLabel>По жанрам</FormLabel>
                    <FormElement>
                        <SelectMultiple
                            options={genreOptions}
                            values={_.compact(pickedGenres || [])}
                            onChange={(v) => setPickedGenres(v)}
                        ></SelectMultiple>
                    </FormElement>
                    <FormLabel>По типу</FormLabel>
                    <FormElement>
                        <SelectMultiple
                            options={typeOptions}
                            values={_.compact(pickedTypes || [])}
                            onChange={(v) => setPickedTypes(v)}
                        ></SelectMultiple>
                    </FormElement>
                    <FormLabel>Сортировать</FormLabel>
                    <FormElement>
                        <Select
                            options={sortOptions}
                            value={order || null}
                            onChange={(v) => setOrder(v)}
                        ></Select>
                    </FormElement>
                    <FormElement>
                        <Select
                            options={sortByOption}
                            value={order_by || null}
                            onChange={(v) => setOrderBy(v)}
                        ></Select>
                    </FormElement>
                    <FormButtons>
                        <Button
                            theme="theme"
                            type="button"
                            onClick={() => setShowFilter(false)}
                        >
                            Закрыть
                        </Button>
                        <Button theme="theme" type="submit">
                            Фильтр
                        </Button>
                    </FormButtons>
                </Form>
            </Popup>
        </>
    )
}
