import moment, { Moment } from 'moment'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IList, ListStatus } from '../types/list'
import { useLocalStorages } from './useLocalStorages'

const colors = new Map([
    [ListStatus.look, '#FFBA49'],
    [ListStatus.scheduled, '#20A39E'],
    [ListStatus.review, '#EF5B5B'],
    [ListStatus.scanned, '#23001E'],
    [ListStatus.forsaken, '#A4A9AD'],
])

export function useListChart(lists: IList[]) {
    const { showChartStorage, chartDateCountStorage } = useLocalStorages()
    const [showChart] = showChartStorage

    const [chartDateCount] = chartDateCountStorage

    const statuses = useMemo(() => Array.from(colors.keys()), [])

    const [t] = useTranslation()

    return useMemo(() => {
        if (!showChart) return null
        const format = (m: Moment) => m.format('DD MM YYYY')

        const filtered = lists.filter(
            (a) =>
                moment(a.updated_at).diff(
                    moment().subtract(chartDateCount, 'day')
                ) > 0
        )

        const dates = Array(chartDateCount)
            .fill('')
            .map((_, i) => format(moment().subtract(i, 'day')))
            .reverse()

        // const statuses = Array.from(new Set(filtered.map(a => a.status)))

        const mappedStatuses = statuses.map((st) => ({
            label: t(`listStatus.${st}`) || '',
            data: dates.map(
                (date) =>
                    filtered.filter(
                        (a) =>
                            format(moment(a.updated_at)) === date &&
                            a.status === st
                    ).length
            ),
            borderWidth: 1,
            backgroundColor: colors.get(st),
            borderColor: colors.get(st),
            tension: 0.3,
        }))

        return {
            labels: dates,
            datasets: mappedStatuses,
        }
    }, [lists, chartDateCount, showChart, statuses, t])
}

// export function useListCount(lists: IList[]): [ListStatus, number][] | null {
//     const labels = Array.from(colors.keys())
//     const showAnimeListCount = useTypedSelector((s) => s.showAnimeListCount)

//     return useMemo(
//         () =>
//             showAnimeListCount
//                 ? labels.map((l) => [
//                       l,
//                       lists.filter((list) => list.status === l).length,
//                   ])
//                 : null,
//         [labels, lists, showAnimeListCount]
//     )
// }
