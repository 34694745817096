import { FC, PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
import { IconButton, IconButtonProps } from './Button/Icon'

export const ButtonFixed: FC<PropsWithChildren<IconButtonProps>> = ({
    theme,
    ...props
}) => {
    return createPortal(
        <IconButton theme={theme || 'theme'} {...props} />,
        document.getElementById('fixed')!
    )
}
