import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import { IGenre } from '../../types/genre'

const initialState = { genres: [] as IGenre[], types: [] as string[] }

export const metaSlice = createSlice({
    name: 'META',
    initialState,
    reducers: {
        setGenres(state, action: PayloadAction<IGenre[]>) {
            state.genres = action.payload
            return state
        },
        setTypes(state, action: PayloadAction<string[]>) {
            state.types = action.payload
            return state
        },
    },
})
