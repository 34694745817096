import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { rui18n } from './langs/ru'

i18next.use(initReactI18next).init({
    lng: 'ru',
    debug: false,
    resources: {
        ru: {
            translation: rui18n,
        },
    },
})
