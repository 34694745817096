import { ChangeEvent, FC, HTMLInputTypeAttribute } from 'react'
import { State } from '../../../types/app'
import { InputContainer, InputContainerProps } from './Container'
import styles from './Input.module.scss'

export interface InputProps extends InputContainerProps {
    value?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
    type?: HTMLInputTypeAttribute
    disabled?: boolean
    name?: string
}

export const Input: FC<InputProps> = ({
    value,
    onChange,
    type,
    placeholder,
    error,
    msg,
    disabled,
    name,
}) => {
    const containerProps = {
        error,
        msg,
    }

    const inputProps = {
        value,
        onChange,
        type,
        placeholder,
        disabled,
        name,
    }

    return (
        <InputContainer {...containerProps}>
            <input {...inputProps} className={styles.field} />
        </InputContainer>
    )
}

export const StateInput = (props: InputProps & { state: State<string> }) => {
    const [st, setSt] = props.state
    return (
        <Input
            {...props}
            value={st}
            onChange={(e) => setSt(e.target.value)}
        ></Input>
    )
}
