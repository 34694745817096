import { useEffect } from 'react'

const defTitle = 'AnimeSpell'
const defDescription =
    'AnimeSpell - смотри аниме на любом устройстве и где бы ты ни был'

export function useTitle(values?: { title?: string; description?: string }) {
    useEffect(() => {
        const title = values?.title ? `${defTitle} | ${values.title}` : defTitle
        const description = values?.description || defDescription
        const dEl = document.querySelector('meta[name="description"]')
        document.title = title

        if (dEl) {
            dEl.setAttribute('content', description)
        }

        return () => {
            document.title = defTitle
            if (dEl) {
                dEl.setAttribute('content', defDescription)
            }
        }
    }, [values])
}
