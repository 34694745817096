import classNames from 'classnames'
import moment from 'moment'
import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { animeAPI } from '../../common/api'
import { useAnime } from '../../hooks/useAnime'
import { IAnime } from '../../types/anime'
import { IRecs } from '../../types/recs'
import { Img } from '.././UI/Img'
import { useTranslation } from 'react-i18next'
import { useAct } from '../../ctx/Error'
import { _ } from '../../common/lodash'

export const Animes: FC<{ animes: IAnime[] }> = ({ animes }) => {
    return (
        <div className="animes">
            {animes.map((anime) => (
                <Anime anime={anime} key={anime.id}></Anime>
            ))}
        </div>
    )
}

export const Anime: FC<{
    anime: IAnime
    rec?: IRecs
    onDeleted?: (animeId: number) => void
}> = ({ anime, rec, onDeleted }) => {
    const { img, list, blur } = useAnime(anime)
    const [t] = useTranslation()
    const act = useAct()

    const rating = useMemo(() => {
        const values = _.compact([
            anime.shikimori_rating,
            anime.kinopoisk_rating,
            anime.imdb_rating,
        ])
        return _.round(
            values.reduce((acc, v) => acc + v, 0) / (values.length || 1),
            2
        )
    }, [anime])

    const deleteAnime = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        act(async () => {
            e.stopPropagation()
            e.preventDefault()
            if (!rec?.id) return
            const answer = window.confirm('Удалить?')
            if (!answer) return
            await animeAPI.deleteRecs(rec.id)
            if (onDeleted) onDeleted(anime.id)
        })
    }

    return (
        <Link to={`/anime/${anime.id}`} className="anime">
            <Img
                src={img}
                alt=""
                className={classNames('anime__img', { blur })}
            ></Img>
            <div className="anime__title">
                {anime.title || anime.title_orig}
            </div>
            {!!rec && (
                <div className="anime__delete" onClick={deleteAnime}>
                    <i className="fa-solid fa-trash"></i>
                </div>
            )}
            {!!list?.status && (
                <div className="anime__userstatus">
                    {t(`listStatus.${list.status}`)}
                </div>
            )}
            {!!anime.release_at && (
                <div className="anime__year">
                    {moment(anime.release_at).year()}
                </div>
            )}
            {!!anime.status && (
                <div
                    className={classNames(
                        'anime__status',
                        `anime__status-${anime.status}`
                    )}
                >
                    {t(`status.${anime.status}`)}
                </div>
            )}

            {!!anime.type && (
                <div className="anime__type">{t(`type.${anime.type}`)}</div>
            )}

            {!!rating && <div className="anime__rating">{rating}</div>}
        </Link>
    )
}
