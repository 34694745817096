import { createSlice } from '@reduxjs/toolkit'
import { IUser } from '../../types/user'
import { IList } from '../../types/list'
import { PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    user: null as IUser | null,
    notifications: 0,
    list: [] as IList[],
}

export const authSlice = createSlice({
    name: 'AUTH',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<IUser | null>) {
            state.user = action.payload
            return state
        },
        setNotifications(state, action: PayloadAction<number>) {
            state.notifications = action.payload
            return state
        },
        setList(state, action: PayloadAction<IList[]>) {
            state.list = action.payload
            return state
        },
    },
})
