import useLocalStorage from 'use-local-storage'

export const useLocalStorages = () => {
    const darkStorage = useLocalStorage('settings/dark', true)
    const chartDateCountStorage = useLocalStorage(
        'settings/chart_date_count',
        14
    )
    const showChartStorage = useLocalStorage('settings/show_chart', true)
    const activeTransitionsStorage = useLocalStorage(
        'settings/transitions',
        true
    )
    const has18Storage = useLocalStorage('main/has-18', false)
    const themeStorage = useLocalStorage('settings/theme', 'default')

    return {
        darkStorage,
        chartDateCountStorage,
        showChartStorage,
        activeTransitionsStorage,
        has18Storage,
        themeStorage,
    }
}
