import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'
import { Loader } from './UI/Loader'

export const Block: FC<PropsWithChildren> = ({ children }) => {
    return <div className="block">{children}</div>
}

export const BlockTitle: FC<PropsWithChildren> = ({ children }) => (
    <div className="block__title">{children}</div>
)
export const BlockContent: FC<PropsWithChildren<{ className?: string }>> = ({
    children,
    className,
}) => <div className={classNames('block__body', className)}>{children}</div>

export const BlockLoader: FC<PropsWithChildren> = ({ children }) => (
    <div className="block__loader">
        <Loader />
    </div>
)

export const BlockLinks: FC<PropsWithChildren> = ({ children }) => {
    return <div className="block__links">{children}</div>
}

export const BlockInfo: FC<PropsWithChildren> = ({ children }) => {
    return <div className="block__info">{children}</div>
}
