import moment from 'moment'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserAvatar } from '../common/mappers/users'
import { makeRegExp } from '../common/utils'
import { useGlobalState } from '../hooks/useGlobalState'
import { useListChart } from '../hooks/useListChart'
import { useAppSelector } from '../hooks/useTypedSelector'
import { useUser } from '../hooks/useUser'
import { IAnime } from '../types/anime'
import { IList, ListStatus } from '../types/list'
import { IUser } from '../types/user'
import { AnimeMini } from './Anime/ListMini'
import { Block, BlockContent, BlockTitle } from './Block'
import { LineChart } from './UI/Chart'
import { Form, FormElement, FormLabel } from './UI/Forms'
import { Img } from './UI/Img'
import { Input } from './UI/Input/Input'
import { Menu } from './UI/Menu'
import { Select } from './UI/Select'
import { Button } from './UI/Button/Button'
import { _ } from '../common/lodash'

export const Profile: FC<{ user: IUser; list?: IList[] }> = ({
    user,
    list,
}) => {
    const auth = useAppSelector((s) => s.auth.user)
    const notifies = useAppSelector((s) => s.auth.notifications)
    const metaTypes = useAppSelector((s) => s.meta.types)
    const userMeta = useUser(user)
    const chartData = useListChart(list || [])
    const [t] = useTranslation()

    const lists = useMemo(() => {
        const statuses = list?.map((l) => l.status) || []
        if (list?.find((l) => l.follow)) statuses.push(ListStatus.follow)
        const statusesSet = new Set(_.compact(statuses))
        return Object.values(ListStatus).filter((status) =>
            statusesSet.has(status)
        )
    }, [list])

    const { state: page, setState: setPage } = useGlobalState(
        `/profile/${user.id}/list/status/`,
        lists[0] as ListStatus | undefined
    )
    const { state: titleFilter, setState: setTitleFilter } = useGlobalState(
        `/profile/${user.id}/filter/title`,
        ''
    )
    const { state: typeFilter, setState: setTypeFilter } = useGlobalState<
        string | null
    >(`/profile/${user.id}/filter/type`, null)

    const animes = useMemo(
        () =>
            list?.reduce((acc, l) => {
                if (!l.anime || !l.status) return acc
                if (!l.anime.title.match(makeRegExp(titleFilter))) return acc
                if (!_.isNull(typeFilter) && l.anime.type !== typeFilter)
                    return acc
                acc.set(l.status, [...(acc.get(l.status) || []), l.anime])

                if (l.follow) {
                    acc.set(ListStatus.follow, [
                        ...(acc.get(ListStatus.follow) || []),
                        l.anime,
                    ])
                }

                return acc
            }, new Map<ListStatus, IAnime[]>(lists.map((l) => [l, []]))),
        [list, lists, titleFilter, typeFilter]
    )

    const currentAnimes = useMemo(() => {
        if (!page) return []
        const animesOnThisPage = animes?.get(page) || []
        // const animeStatuses = Array.from(new Set(animesOnThisPage.map(anime => anime.status || 'null')))

        return Array.from(
            animesOnThisPage
                .reduce((acc, anime) => {
                    const status = anime.status || 'null'
                    const current = acc.get(status) || []
                    acc.set(status, [...current, anime])
                    return acc
                }, new Map<string, IAnime[]>())
                .entries()
        )
    }, [animes, page])

    // function copyLink() {
    //     copy(`${window.location.origin}/users/${user.id}`)
    //     toast('Скопировано')
    // }

    return (
        <>
            <div className="profile">
                <div className="profile__side">
                    <Block>
                        <BlockTitle>{user.name}</BlockTitle>
                        <BlockContent className="profile__infoblock">
                            <div className="profile__avatar">
                                <Img src={getUserAvatar(user)}></Img>
                            </div>
                            <div className="profile__info">
                                <span>{userMeta.role || ''}</span>
                            </div>
                            <div className="profile__info">
                                <span>
                                    {moment(user.created_at).format(
                                        'D.MM.YYYY'
                                    )}
                                </span>
                            </div>
                            {!!user.description && (
                                <pre className="profile__info profile__description">
                                    {user.description}
                                </pre>
                            )}
                            {!!userMeta.gender && (
                                <div className="profile__info">
                                    Пол: <span>{userMeta.gender}</span>
                                </div>
                            )}
                            {!!user.city && (
                                <div className="profile__info">
                                    Город: <span>{user.city}</span>
                                </div>
                            )}
                            {/* <div className="profile-btn">
                            <div
                                className="profile-btn__icon"
                                onClick={copyLink}
                            >
                                <i className="fa-solid fa-copy"></i>
                            </div>
                            <div className="profile-btn__name">Коп. ссылку</div>
                        </div> */}
                        </BlockContent>
                    </Block>
                </div>
                <div className="profile__main">
                    <div className="profile__content">
                        {!!chartData && (
                            <div className="profile__chart">
                                <LineChart data={chartData}></LineChart>
                            </div>
                        )}
                        {currentAnimes.map(([status, animes]) => (
                            <Block key={status}>
                                <BlockTitle>
                                    {t(`status.${status}`).toString()}
                                </BlockTitle>
                                <BlockContent className="anime-mini__container">
                                    {animes.map((anime) => (
                                        <AnimeMini
                                            anime={anime}
                                            key={anime.id}
                                        ></AnimeMini>
                                    ))}
                                </BlockContent>
                            </Block>
                        ))}
                    </div>

                    <div className="profile__side profile__menu">
                        <Block>
                            <BlockTitle>Списки</BlockTitle>
                            <BlockContent>
                                <Menu>
                                    {lists.length > 0 ? (
                                        lists.map((list) => (
                                            <Button
                                                active={list === page}
                                                key={list}
                                                onClick={() => setPage(list)}
                                                theme="transparent"
                                                infoAfter={
                                                    animes?.get(list)?.length ||
                                                    0
                                                }
                                            >
                                                {t(`listStatus.${list}`)}
                                            </Button>
                                        ))
                                    ) : (
                                        <Button disabled>
                                            Списки отсутствуют
                                        </Button>
                                    )}
                                </Menu>
                            </BlockContent>
                        </Block>
                        {auth?.id === user.id && (
                            <Block>
                                <BlockContent>
                                    <Menu>
                                        <Button
                                            to="/notifications"
                                            theme="transparent"
                                            alert={
                                                notifies > 0
                                                    ? notifies
                                                    : undefined
                                            }
                                        >
                                            Уведомления
                                        </Button>
                                    </Menu>
                                </BlockContent>
                            </Block>
                        )}
                        <Block>
                            <BlockTitle>Фильтр</BlockTitle>
                            <BlockContent>
                                <Form>
                                    <FormLabel>По названию</FormLabel>
                                    <FormElement>
                                        <Input
                                            value={titleFilter}
                                            onChange={(e) =>
                                                setTitleFilter(e.target.value)
                                            }
                                            placeholder="..."
                                        ></Input>
                                    </FormElement>
                                    <FormLabel>По типу</FormLabel>
                                    <FormElement>
                                        <Select
                                            options={metaTypes.map((meta) => [
                                                meta,
                                                t(`type.${meta}`),
                                            ])}
                                            value={typeFilter}
                                            onChange={(val) =>
                                                setTypeFilter(val)
                                            }
                                        ></Select>
                                    </FormElement>
                                </Form>
                            </BlockContent>
                        </Block>
                    </div>
                </div>
            </div>
        </>
    )
}
