import classNames from 'classnames'
import moment from 'moment'
import { FC, Suspense, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { animeRatingsToAsk, useAnime } from '../../hooks/useAnime'
import { useAppSelector } from '../../hooks/useTypedSelector'
import { IAnime } from '../../types/anime'
import { IList } from '../../types/list'
import { Block, BlockContent, BlockTitle } from '.././Block'
import { Popup } from '.././Popup'
import { ButtonFixed } from '.././UI/ButtonFixed'
import { FormButtons, FormLabel } from '.././UI/Forms'
import qs from 'query-string'
import { Img } from '.././UI/Img'
import { useTranslation } from 'react-i18next'
import { AnimeListEditor } from './ListEditor'
import { PageLoader } from '../UI/Loader'
import { AnimeAdminDetail } from '../admin'
import { Button } from '../UI/Button/Button'
import { IconButton } from '../UI/Button/Icon'
import { useLocalStorages } from '../../hooks/useLocalStorages'

export const AnimeDetail: FC<{ anime: IAnime; lists?: IList[] }> = ({
    anime,
}) => {
    const { img, blur } = useAnime(anime)
    const { has18Storage } = useLocalStorages()
    const [admin, setAdmin] = useState(false)
    const [editor, setEditor] = useState(false)
    const [full, setFull] = useState(false)
    const [showPlayer, setShowPlayer] = useState(true)
    const auth = useAppSelector((s) => s.auth.user)
    const [has18, setHas18] = has18Storage
    const [t] = useTranslation()
    const navigate = useNavigate()

    function refreshPlayer() {
        setShowPlayer(false)
        setTimeout(() => setShowPlayer(true), 0)
    }

    // const listCounts = useListCount(lists || [])

    return (
        <>
            <Block>
                <BlockTitle>{anime.title}</BlockTitle>
                <BlockContent className="anime-detail">
                    <div className="anime-detail__body">
                        <div className="anime-detail__infos">
                            {!!anime.title_orig && (
                                <div className="anime-detail__item anime-detail__info">
                                    Оригинальное название:{' '}
                                    <span>{anime.title_orig}</span>
                                </div>
                            )}

                            {!!anime.other_title && (
                                <div className="anime-detail__item anime-detail__info">
                                    Остальные названия:{' '}
                                    <span>{anime.other_title}</span>
                                </div>
                            )}

                            {!!anime?.genres?.length && (
                                <div className="anime-detail__item anime-detail__genres anime-detail__info">
                                    Жанры:{' '}
                                    {anime.genres.map((genre) => (
                                        <Link
                                            className="anime-detail__genre"
                                            key={genre.id}
                                            to={{
                                                pathname: '/anime',
                                                search: qs.stringify({
                                                    genres: [genre.title],
                                                }),
                                            }}
                                        >
                                            <span>{genre.title}</span>
                                        </Link>
                                    ))}
                                </div>
                            )}

                            {!!anime.type && (
                                <div className="anime-detail__item anime-detail__info">
                                    Тип: <span>{t(`type.${anime.type}`)}</span>
                                </div>
                            )}
                            {!!anime.rating && (
                                <div className="anime-detail__item anime-detail__info">
                                    Рейтинг: <span>{anime.rating}</span>
                                </div>
                            )}
                            {!!anime.release_at && (
                                <div className="anime-detail__item anime-detail__info">
                                    Год:{' '}
                                    <span>
                                        {moment(anime.release_at).format(
                                            'YYYY'
                                        )}
                                    </span>
                                </div>
                            )}
                            {!!anime.full_episodes && (
                                <div className="anime-detail__item anime-detail__info">
                                    Всего эпизодов:{' '}
                                    <span>{anime.full_episodes}</span>
                                </div>
                            )}
                            {!!anime.status && (
                                <div className="anime-detail__item anime-detail__info">
                                    Статус:{' '}
                                    <span>{t(`status.${anime.status}`)}</span>
                                </div>
                            )}
                            <pre className="anime-detail__description anime-detail__info">
                                {anime.description || 'Нет описания'}
                            </pre>
                            {!!anime.shikimori_rating && (
                                <div className="anime-detail__item anime-detail__info">
                                    Рейтинг шикимори:{' '}
                                    <span>{anime.shikimori_rating}</span>
                                    {anime.shikimori_votes
                                        ? ` (${anime.shikimori_votes} голосов)`
                                        : ''}
                                </div>
                            )}
                            {!!anime.kinopoisk_rating && (
                                <div className="anime-detail__item anime-detail__info">
                                    Рейтинг кинопоиска:{' '}
                                    <span>{anime.kinopoisk_rating}</span>
                                    {anime.shikimori_votes
                                        ? ` (${anime.kinopoisk_votes} голосов)`
                                        : ''}
                                </div>
                            )}
                            {!!anime.imdb_rating && (
                                <div className="anime-detail__item anime-detail__info">
                                    Рейтинг IMDB:{' '}
                                    <span>{anime.imdb_rating}</span>
                                    {anime.shikimori_votes
                                        ? ` (${anime.imdb_votes} голосов)`
                                        : ''}
                                </div>
                            )}

                            {!!anime.shikimori_id && (
                                <a
                                    href={`https://shikimori.me/animes/${anime.shikimori_id}`}
                                    target="_blank"
                                    className="anime-detail__link"
                                    rel="noreferrer"
                                >
                                    Первоисточник Шикимори
                                </a>
                            )}
                            <div className="anime-detail__buttons">
                                {!!anime.player && (
                                    <IconButton onClick={refreshPlayer}>
                                        <i className="fa-solid fa-arrows-rotate"></i>
                                    </IconButton>
                                )}
                                {auth?.role === 'admin' && (
                                    <IconButton
                                        onClick={() => setAdmin((o) => !o)}
                                    >
                                        <i className="fa-solid fa-screwdriver-wrench"></i>
                                    </IconButton>
                                )}
                                {/* <div
                                    className="anime-detail__btn anime-detail__btn-disabled"
                                    data-tip
                                    data-for="anime-detail-info"
                                >
                                    <i className="fa-solid fa-circle-info"></i>
                                </div>
                                <Tooltip id="anime-detail-info">
                                    Как правильно смотреть аниме в случае
                                    блокировки?
                                    <br />
                                    Как правило, система не следит за вашим
                                    регионом в процессе просмотра.
                                    <br />
                                    Следовательно, вы может кратковременно
                                    включить VPN, дожаться первых секунд
                                    просмотра, а затем его выключить.
                                </Tooltip> */}
                            </div>
                        </div>
                        <div className="anime-detail__img">
                            <Img
                                src={img}
                                onClick={() => setFull((o) => !o)}
                                className={classNames({ blur })}
                            ></Img>
                            {/* {!!lists?.length &&
                            listCounts?.map(([st, count]) => (
                                <div
                                    className="anime-detail__item anime-detail__info"
                                    key={st}
                                >
                                    {listNames.get(st)}: <span>{count}</span>
                                </div>
                            ))} */}
                        </div>

                        <Img
                            className={classNames('anime-detail__full', {
                                'anime-detail__full-active': full,
                            })}
                            onClick={() => setFull(false)}
                            src={img}
                        ></Img>
                    </div>
                    {admin && (
                        <Suspense fallback={<PageLoader></PageLoader>}>
                            <AnimeAdminDetail anime={anime}></AnimeAdminDetail>
                        </Suspense>
                    )}
                    {!!anime.player ? (
                        showPlayer && (
                            <iframe
                                src={anime.player}
                                className="anime-detail__player"
                                title={anime.title}
                                allowFullScreen
                            ></iframe>
                        )
                    ) : (
                        <div className="anime-detail__item anime-detail__info">
                            На данный момент плеер для данного аниме
                            отсутствует.
                        </div>
                    )}
                </BlockContent>
            </Block>
            {animeRatingsToAsk.has(anime.rating || '') && (
                <Popup state={[!has18, () => {}]} title="Вам есть 18?">
                    <FormLabel>
                        Данный тайтл содержит рейтинг {anime.rating}, поэтому
                        подтвердите, что вам есть 18 лет
                    </FormLabel>
                    <FormButtons>
                        <Button theme="theme" onClick={() => navigate('/')}>
                            Нет
                        </Button>
                        <Button theme="theme" onClick={() => setHas18(true)}>
                            Есть
                        </Button>
                    </FormButtons>
                </Popup>
            )}
            {!!auth && (
                <>
                    <ButtonFixed onClick={() => setEditor(true)}>
                        <i className="fa-solid fa-list-check"></i>
                    </ButtonFixed>
                    <AnimeListEditor
                        show={[editor, setEditor]}
                        anime={anime}
                    ></AnimeListEditor>
                </>
            )}
        </>
    )
}
