import { FC, useMemo } from 'react'
import { Profile } from '../components/Profile'
import { useTitle } from '../hooks/useTitle'
import { useAppSelector } from '../hooks/useTypedSelector'

export const ProfilePage: FC = () => {
    const auth = useAppSelector((s) => s.auth.user)
    const listMap = useAppSelector((s) => s.auth.list)
    const list = useMemo(() => Array.from(listMap?.values() || []), [listMap])

    useTitle({
        title: `Пользователь ${auth?.name}`,
        description: auth?.description || undefined,
    })

    return !!auth ? <Profile user={auth} list={list}></Profile> : null
}
