import { useMemo } from 'react'
import { images } from '../assets/assets'
import { IAnime } from '../types/anime'
import { useMappedSelector } from './useMappedSelector'
import { useLocalStorages } from './useLocalStorages'

export const animeRatingsToAsk = new Set(['R', 'NC-17', 'Rx'])

export function useAnime(anime?: IAnime) {
    const { has18Storage } = useLocalStorages()
    const [has18] = has18Storage
    const { mapListByAnimeId } = useMappedSelector()
    const img = useMemo(() => anime?.img || images.unknown, [anime])
    const blur = useMemo(
        () => !has18 && anime?.rating === 'Rx',
        [has18, anime?.rating]
    )
    const list = useMemo(
        () => (!!anime ? mapListByAnimeId?.get(anime.id) : undefined),
        [anime, mapListByAnimeId]
    )

    return { img, list, blur }
}
